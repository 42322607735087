// src/store/index.js

import { createStore } from 'vuex';
// import axios from 'axios';

import { localStoragePlugin } from '@/localstorage';

export default createStore({
  state: {
    user: null, // User object
    user_avatar: null, // User object
    token: null, // JWT token
    isAuthenticated: false, // Authentication status
    conversation_id: "",
    theme_mode: 'light',
    language: "en",
    active_conversation: [],
    conversation_history: [],
    latest_conversation: [],
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_AVATAR(state, data) {
      state.user_avatar = data;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_CONVERSATION_ID(state, id){
      state.conversation_id = id;
    },
    SET_ACTIVE_CONV(state, data) {
      state.active_conversation = data;
    },
    SET_CONV_HISTORY(state, data) {
      state.conversation_history = data;
    },
    SET_LATEST_CONV(state, data) {
      state.latest_conversation = data;
    },
    SET_THEME_MODE(state, data)
    {
      state.theme_mode = data
    },
    SET_LANGUAGE(state, data)
    {
      state.language = data
    }
  },
  actions: {
   
    logout({ commit }) {
      // Clear user data and token
      commit('SET_USER', null);
      commit('SET_USER_AVATAR', null);
      commit('SET_TOKEN', null);
      commit('SET_AUTHENTICATED', false);
      commit('SET_CONVERSATION_ID', "");
    },
  },
  getters: {
    getUser: (state) => state.user,
    getUserAvatar: (state) => state.user_avatar,
    getToken: (state) => state.token,
    isAuthenticated: (state) => state.isAuthenticated,
    conversationId: (state) => state.conversation_id,

    getAcctiveConversation: (state) => state.active_conversation,
    getConversationHistory: (state) => state.conversation_history,
    getLatestConversation: (state) => state.latest_conversation,
    getThemeMode: (state) => state.theme_mode,
    getLanguage: (state) => state.language,
  },
  plugins: [localStoragePlugin], // Use the localStoragePlugin to persist state
});
