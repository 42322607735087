<template>
    <section class="card h-100 p-0">
        <div class="card-header bg-transparent border-0 px-0 shadow">
            <div class="container">
                <nav class="d-flex gap-2 overflow-x-auto w-100">
                    <router-link class="button button_sm" :to="{ name:'search', query: { q: $route.query.q } }">{{$t('search_all')}}</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchWebComponent', query: { q: $route.query.q } }">{{$t('search_all_web')}}</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchImageComponent', query: { q: $route.query.q } }">{{$t('search_all_image')}}</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchVideoComponent', query: { q: $route.query.q } }">{{$t('search_all_video')}}</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchNewsComponent', query: { q: $route.query.q } }">{{$t('search_all_news')}}</router-link>
                    <router-link class="button button_sm" :to="{ name:'SearchGPTComponent', query: { q: $route.query.q } }">{{$t('search_all_gpt')}}</router-link>
                </nav>
                <p class="text-secondary fw-light m-0 p-0 text-start" v-if="search_engine_response != null" >{{$t('Available_data_based_on')}} <span class="fw-bold">{{ $route.query.q }}</span></p>
            </div>
        </div>
        <div class="card-body overflow-auto p-0">
            <div class="container my-3">
                
                        
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="h5 text-secondary">{{$t('Generated_by_india_gpt')}}</h3>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex align-items-top">
                                    <div class="p-2">
                                        <img src="../../../assets/logo.png" alt="..." class="rounded" height="50">
                                    </div>
                                    <div class="flex-grow-1">
                                        <blockquote class="text-secondary shadow-sm p-2 rounded h-100">
                                            <div class="flex-grow-1 text-secondary" v-html="streamedText.replace(/\n/g, '<br />')" v-if="streamedText != ''"></div> 
                                            <div v-else>
                                                <Skeleton class="mb-2"></Skeleton>
                                                <Skeleton width="10rem" class="mb-2"></Skeleton>
                                                <Skeleton width="5rem" class="mb-2"></Skeleton>
                                                <br>
                                                <Skeleton class="mb-2"></Skeleton>
                                                <Skeleton class="mb-2"></Skeleton>
                                                <br>
                                                <Skeleton class="mb-2"></Skeleton>
                                                <Skeleton class="mb-2"></Skeleton>
                                            </div>
                                        </blockquote>
                                        <div>
                                        <button v-if="gpt_task" class="button button_sm button_light mt-3" @click="fetchStreamedText()"><i class="bi bi-arrow-clockwise"></i> {{$t('Regenerate')}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                
                    </div>
                    <div class="col-md-6">
                        <div v-if="search_engine_response != null" class="mb-3">
                            <div class="d-flex gap-2 overflow-x-auto w-100 mb-3" v-if="search_engine_response.images">
                                <div class="col-7 col-md-5 image_block" v-for="item in search_engine_response.images.value" :key="item" @click="searchPopup(item)">
                                    <img :alt="item.name" :title="item.name" :src="item.thumbnailUrl" height="200" class="object-fit-cover border rounded shadow w-100" onerror="this.classList.add('ImageError')">
                                    <abbr class="fw-bold text-secondary text-decoration-none" :title="item.name">{{ item.name.substring(0,22) }}</abbr>
                                </div>
                            </div>
                        </div>  
                        <div class="card card-body mb-3 border-0 shadow-sm">
                            <div class="row g-3" v-if="search_engine_response != null">
                                <div class="col-12 p-0" v-for="item in search_engine_response.webPages.value" :key="item">
                                    <div class="card card-body border-0 p-0">
                                        <h5 class="mb-2 h6 text-secondary"><a :href="item.url" target="_blank" class="text-decoration-none">{{item.name}}</a></h5>
                                        <p class="text-secondary">
                                            {{ item.snippet }}
                                        </p>
                                    </div>
                                </div>
                               
                            </div>   
                            <div v-else>
                                <div>
                                    <div v-if="search_engine_loading">
                                        <Skeleton width="10rem" class="mb-2"></Skeleton>
                                        <br>
                                        <Skeleton class="mb-2"></Skeleton>
                                        <Skeleton class="mb-2"></Skeleton>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
                <div class="row g-3" v-if="search_engine_response != null">
                    <div v-if="search_engine_response.relatedSearches" class="col-12">
                        <h4 class="text-secondary my-3">Related searches</h4>
                        <div class="row g-3">
                            <div class="col-md-6" v-for="item in search_engine_response.relatedSearches.value" :key="item">
                                <a class="rounded py-1 px-2 border text-decoration-none" :href="'?q='+item.text"><i class="bi bi-search"></i> {{item.text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-center bg-transparent">
            <div class="searchboxForm">
                <form class="d-flex gap-1 align-items-center" id="searchBox" @submit.prevent="searching()">
                    <div class="d-flex w-100 bg-transparent rounded">
                        <input :disabled="!gpt_task" type="search" autocomplete="off" @keyup="getKeywordSuggestions()" autofocus required name="q" list="suggetions" class="searchInput form-control form-control-lg border"  :class="{ active: question_form.question, 'text-danger': hasError }" :placeholder="$t('placeholder_serach')" aria-label="With textarea" v-model="question_form.question">
                        <datalist id="suggetions"><option :value="suggestion" v-for="suggestion in search_keyword_suggesion" :key="suggestion">{{suggestion}}</option></datalist>
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                        <button type="button" v-if="isMicAvailable" class="btn btn-outline-default waves searchButton" :class="{ active: isListening }" @click="toggleRecognition" :title="isListening ? 'Stop Listening' : 'Start Listening'">
                            <i class="bi bi-mic-fill h3 icon"></i>
                        </button>
                        <button class="btn btn-outline-default searchButton" type="submit" :disabled="!gpt_task">
                            <i class="bi bi-send-fill h3"></i>
                        </button>
                    </div>
                </form>
            </div>
            
        </div>
    </section>



    <div v-if="search_popup">
        <div class="pro_modal show" >
            <div class="pro_modal_dialog">
                <div class="pro_modal_content p-3">
                    <div v-if="search_result_type == 'image'">
                        <div class="pro_modal_header">
                            <h5 class="pro_modal_title"><a :href="search_popup_data.hostPageUrl" target="_blank" class="text-decoration-none">{{ search_popup_data.name }}</a></h5>
                            <button type="button" class="btn-close" @click="searchPopup(this.search_popup_data)"></button>
                        </div>
                        <img :src="search_popup_data.thumbnailUrl" class="object-fit-contain border rounded w-100">

                        <div class="d-flex gap-2 mt-2">
                            <p class="p-0 m-0 w-100 text-secondary">Images may be subject to copyright. </p>
                            <a class="badge bg-warning text-decoration-none" :href="search_popup_data.contentUrl" target="_blank">View Image</a>
                            <a class="badge bg-warning text-decoration-none" :href="search_popup_data.hostPageUrl" target="_blank">Visit Website</a>
                        </div>
                        
                    </div>
                    <div v-if="search_result_type == 'video'">
                        <div class="pro_modal_header">
                            <h5 class="pro_modal_title"><a :href="search_popup_data.contentUrl" target="_blank" class="text-decoration-none text-dark">{{ search_popup_data.name }}</a></h5>
                            <button type="button" class="btn-close" @click="searchPopup(this.search_popup_data)"></button>
                        </div>
                        <div v-if="search_popup_data.embedHtml">
                            <div v-html="search_popup_data.embedHtml" class="videoPlayer"></div>
                        </div>
                        <div v-else>
                            <div class="card-body p-0">
                                <img height="240" :src="search_popup_data.images.medium" class="object-fit-contain border rounded w-100 bg-dark">
                                <p>{{ search_popup_data.description }}</p>
                                <span class="badge bg-success">Views: {{ search_popup_data.statistics.viewCount }}</span>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<style></style>

<script>
    import { useHead } from "@vueuse/head";
    import { useRoute } from 'vue-router';
    import axios from 'axios';
    import Skeleton from 'primevue/skeleton';
    import { containsBadWord } from '../../../badWords';
    import { ref } from 'vue';
  
    export default{
        name : "SearchResultView",
        components:{
            Skeleton,
        },
        data() {
            return {
            conversation_id : this.$store.getters.conversationId,
            conversation_data : '',
            search_engine_response: null,
            search_keyword_suggesion: [],
            question_form : {
                question : ""
            },
            search_engine_loading: false,
            wikipedia_response_loading: false,
            gpt_loading: false,
    
            isListening: false,
            recognition: null,
            isMicAvailable : true,
    
            search_result_type:"image",
            search_popup: false,
            search_popup_data: {},

            streamedText:'',
            gpt_task: false
            }
        },
        created()
        {
            // this.getConversations()
            this.get_search_keyword();
            this.searching();   
        },
        methods: {
            get_search_keyword()
            {
                const route = useRoute()
                this.question_form.question = route.query.q
            },
            async fetchStreamedText() {
                this.streamedText = "";
                this.gpt_loading = true
                this.gpt_task = false
                const streamUrl = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/gpt/campaign?prompt=${this.question_form.question}`;
                
                await fetch(streamUrl).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    this.gpt_loading = false
                    const reader = response.body.getReader();
                    const streamDecoder = new TextDecoder();
                    const processStream = ({ done, value }) => {
                        if (done) {
                            console.log('Stream complete');
                            this.gpt_task = true;
                            return;
                        }
                        const chunk = streamDecoder.decode(value, { stream: true });
                        this.streamedText += chunk;
                        return reader.read().then(processStream);
                    };
                    return reader.read().then(processStream);
                }).catch(error => {
                        console.error('Fetch failed:', error);
                });
            },
            
            removeCharacter(data) {
                return data.replace("?", "");
            },

            badWordFilter()
            {
                return containsBadWord(this.question_form.question);
            },

            toggleRecognition()
            {
                this.checkMicAvailability()
                if (this.isMicAvailable)
                {
                    if (this.isListening)
                    {
                        this.recognition.stop();
                    }
                    else
                    {
                        this.recognition.start();
                    }
                }
                else
                {
                    this.$toast.error("Mic is not available", {
                        icon: "dangerous",
                    }); 
                }            
            },

            checkForCommand(result)
            {
                const t = result.transcript;
                if (t.includes('stop'))
                {
                    this.recognition.stop();
                }
            },

        
            checkMicAvailability()
            {
                navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                // Code for success
                    console.log(stream)
                    this.isMicAvailable = true
                    this.checkMicAvailability()
                }).catch(err => {
                    console.log(err)
                    this.isMicAvailable = false
                })
            },

            async getSearchEngineResponse(type)
            {
                // calling api
                if(this.question_form.question != "")
                {
                    this.search_engine_response = null;
                    this.search_engine_loading = true;
                    const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/guest/bing_search/${type}/${this.removeCharacter(this.question_form.question)}/`;
                    await axios.get(API_URL).then((response) => {
                        if(response.status == 204)
                        {
                            // this.$toast.error(response.data.message, {
                            //     icon: "dangerous",
                            // }); 
                            this.search_engine_loading = false;
                        }
                        else if(response.status == 200)
                        {
                            this.search_engine_response = response.data.data
                            this.search_engine_loading = false;
                        }
                    })
                    .catch(() => {
                    // Handle errors
                        // console.log(error);
                        // this.$toast.error("Ops something is wrong. try again later", {
                        //     icon: "dangerous",
                        // }); 
                        this.search_engine_loading = false;
                    }); 
                }
                // calling api
            },

            async searching()
            {
                if(this.question_form.question != "")
                {
                    this.updateTitle(this.removeCharacter(this.question_form.question)+" - India GPT Search");
                    this.$router.push({path: this.$route.fullPath, query: {q: this.removeCharacter(this.question_form.question)} });

                    let wordcheker = containsBadWord(this.question_form.question)
                    if(!wordcheker)
                    {
                        await this.getSearchEngineResponse("web");
                        await this.fetchStreamedText(this.question_form.question);
                    }
                    else
                    {
                        // alert(`Please avoid using offensive or inappropriate language (${wordcheker}) as it's against India's government rules.`);
                        this.$toast.error(`Please avoid using offensive or inappropriate language (${wordcheker}) as it's against India's government rules.`); 
                    }
                }
                else
                {
                    this.$toast.error("Questions are required"); 
                }
            },

            searchPopup(data)
            {
                this.search_popup = !this.search_popup
                this.search_popup_data = data
            },

            getKeywordSuggestions()
            {
                if(this.question_form.question != "")
                {
                    const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/search/suggestions/${this.question_form.question}/`;
                    const headers = { 
                        "Authorization": `Bearer ${this.$store.getters.getToken}`,
                    };

                    axios.get(API_URL, {headers})
                    .then((response) => {
                        if(response.status == 204)
                        {
                            // this.$toast.error(response.data.message, {
                            //     icon: "dangerous",
                            // }); 
                        }
                        else if(response.status == 200)
                        {
                            this.search_keyword_suggesion = response.data.suggestion
                        }

                    })
                    .catch(() => {
                        this.search_engine_loading = false;
                    });
                }
            }
        },
        setup()
        {
            const pageTitle = ref('Search - India GPT');

            useHead({
                title: pageTitle
            });
            // Function to change the title
            function updateTitle(newTitle) {
                pageTitle.value = newTitle;
            }
            return {
                updateTitle,
            };
        },
        mounted()
        {
            // to use mic
            this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
    
            this.recognition.onstart = () => {
                console.log('Recognition Started');
                this.isListening = true;
            };
    
            this.recognition.onend = () => {
                console.log('Recognition Stopped');
                this.isListening = false;
            };
    
            this.recognition.onresult = (evt) => {
                let finalTranscript = '';
                for (let i = 0; i < evt.results.length; i++) {
                    const result = evt.results[i];
                    if (result.isFinal) {
                    finalTranscript += result[0].transcript + ' ';
                    this.checkForCommand(result[0]);
                    }
                }
                this.question_form.question = finalTranscript.trim();
            };
            // to use mic
        },    
    }
</script>
