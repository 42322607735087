<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                <h1 class="h4"><dt>IndiaGPT Privacy Policy</dt></h1>
            </div>
            <div class="card-body p-4">
                <h1 class="h5">Effective Date: 1.1.24</h1>
                <hr>
                <div>
                    <dl>
                        <dd>Welcome to IndiaGPT! We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, share, and protect information about our users and outlines your rights regarding your personal information.</dd>
                         <dt>1. Information We Collect</dt>
                        <dd>
                            <p>We collect information to provide better services to all our users. The types of information we collect include:</p>
                            <ul>
                                <li>Personal Identification Information: Name, email address, phone number, etc., provided when you sign up for our services, newsletters, or participate in our online forums.</li>
                                <li>Usage Information: Details of how you use IndiaGPT, including interaction data with our platform and AI responses.</li>
                                <li>Technical Data: IP address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access IndiaGPT.</li>
                                <li>Cookies and Similar Technologies: We use cookies and similar tracking technologies to track activity on our service and hold certain information to enhance and personalize your experience</li>
                            </ul>
                        </dd>
                        <dt>2. How We Use Your Information</dt>
                        <dd>
                            <p>We use the information we collect to:</p>
                            <ul>
                                <li>Provide, maintain, and improve our services.</li>
                                <li>Develop new products, services, features, and functionality.</li>
                                <li>Communicate with you, including sending you updates, support messages, and administrative messages.</li>
                                <li>Understand and analyze how you use our services for improving user experience.</li>
                                <li>Ensure legal compliance and protect our platform from misuse.</li>
                            </ul>
                        </dd>
                        <dt>3. Sharing Your Information</dt>
                        <dd>
                            <p>We do not share your personal information with companies, organizations, or individuals outside of IndiaGPT except in the following cases:</p>
                            <ul>
                                <li>With Your Consent: We will share personal information outside of IndiaGPT when we have your consent</li>
                                <li>For External Processing: We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.</li>
                                <li>For Legal Reasons: We will share personal information with companies, organizations, or individuals outside of IndiaGPT if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process, or enforceable governmental request.</li>
                            </ul>
                        </dd>
                        <dt>4. Your Data Protection Rights</dt>
                        <dd>
                            <p>You have the right to:</p>
                            <ul>
                                <li>Access, correct, update, or request deletion of your personal information.</li>
                                <li>Object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information.</li>
                                <li>Withdraw your consent at any time if we have collected and processed your personal information with your consent.</li>
                                <li>Complain to a data protection authority about our collection and use of your personal information</li>
                            </ul>
                        </dd>
                        <dt>5. Data Security</dt>
                        <dd>
                            <p>We use appropriate technical and organizational measures to protect the personal information that we collect and process about you. The measures we use are designed to provide a level of security appropriate to the risk of processing your personal information.</p>
                        </dd>
                        <dt>6. Use of Third-Party APIs</dt>
                        <dd>
                            <p>IndiaGPT integrates with various third-party APIs to enhance our services and provide you with the most accurate, up-to-date information and functionalities. These third-party services may include, but are not limited to, data analytics, communication platforms, and other AI technologies that complement our offerings.</p>
                            
                        </dd>
                        <dt>Third-Party Services and Data Accuracy</dt>
                        <dd>
                            <ul>
                                <li>Data Collection and Use: When you interact with services powered by third parties, such third parties may collect information about you independently from us. The collection, use, and sharing of your information by these third parties are subject to their respective privacy policies and terms of service, which we encourage you to review.</li>
                                <li>Liability for Inaccurate Information: While we strive to select third-party services that are reputable and reliable, IndiaGPT cannot guarantee the accuracy, completeness, or reliability of the information provided through these third-party APIs. The information obtained from these services is provided "as is" without any representation or endorsement made and without warranty of any kind, whether expressed or implied.</li>
                                <li>Limitation of Liability: IndiaGPT shall not be liable for any errors, inaccuracies, or omissions in the content provided through third-party APIs nor for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the third-party services integrated with our platform.</li>
                            </ul>
                            <p>We recommend exercising caution and conducting your due diligence before relying on the information provided through third-party services. For any concerns related to the accuracy of information obtained from third-party APIs, please direct your inquiries to the respective third-party service providers.</p>
                        </dd>
                        <dt>7. International Transfers</dt>
                        <dd>
                            <p>Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.</p>
                        </dd>
                        <dt>8. Children's Privacy</dt>
                        <dd>
                            <p>Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us.</p>
                        </dd>
                        <dt>9. Changes to This Privacy Policy</dt>
                        <dd>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                        </dd>
                        <dt>10. Contact Us</dt>
                        <dd>
                            <p>If you have any questions about this Privacy Policy, please contact us by email at <a class="text-secondary" style="text-decoration: none;" href="mailto:info@indiagpt.com">info@indiagpt.com</a>.</p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "PrivacyPolicyScreen"
}
</script>

<style>

</style>