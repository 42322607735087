<template>
    <header>
        <nav class="navbar navbar-expand-lg fixed-top mainNavbar py-2" :class="{ 'stickyHeader': isScrolled }">
            <div class="container">
                <a class="navbar-brand" href="/#home">
                    <img class="me-3 brand_logo" src="../assets/main-logo.webp" alt="main logo" >
                </a>
                <!-- <DownloadPWA class="d-none d-md-block"></DownloadPWA> -->
                <div class="d-flex gap-2 align-items-center" v-if="$store.getters.isAuthenticated">
                    <router-link class="text-decoration-none" :to="{ name:'ProfileScreen' }">
                    <div class="d-flex align-items-center avatar_section">
                        <div class="flex-shrink-0">
                            <div v-if="$store.getters.getUserAvatar != ''">
                                <img :src=$store.getters.getUserAvatar width="40" height="40" class="object-fit-cover border rounded" alt="User Avatar">
                            </div>
                            <div v-else>
                                <img src="../assets/avatar.png" width="40" height="40" class="object-fit-cover border rounded" alt="avatar">
                            </div>
                        </div>
                        <div class="flex-grow-1 mx-3 text-start d-none d-md-block">
                            <h5 class="mb-0">{{ $store.getters.getUser.first_name }}</h5>
                            <p class="mb-0"><small>{{ $store.getters.getUser.email }}</small></p>
                        </div>
                    </div>
                    </router-link>
                </div>
                <div class="d-flex gap-4 align-items-center" v-else>
                    <router-link class="button button_sm" :to="{ name:'LoginView' }">{{$t('menu_login')}}</router-link>
                </div>
            </div>
        </nav>
    </header>
    <div class="bg-white" style="padding-top: 100px;">
        <router-view/>
    </div>
    <footer class="bg-white">
        <section id="footer" data-aos="fade-in">
            <div class="container py-5 text-center">
                <div class="row align-items-center justify-content-center my-5">
                    <div class="col-md-12  py-5">
                        <h4 class="sub-headline-decor text-white">{{$t('headline_welcome_note_footer')}}</h4>
                                
                        <p class="description mt-3 text-light">
                            {{$t('description_welcome_note_footer')}}
                        </p>

                        <div class="d-flex gap-2 justify-content-center" role="search" v-if="!$store.getters.isAuthenticated">
                            <router-link class="button button_dark button_sm_sm" :to="{ name:'RegisterView' }">{{$t('menu_register')}}</router-link>
                            <router-link class="button button_light button_sm_sm" :to="{ name:'LoginView' }">{{$t('menu_login')}}</router-link>
                        </div>
                        <div class="mt-3" role="search">
                            <h1 class="sub-headline-decor text-white fw-bolder text-center my-5">{{$t('download_our_app')}}</h1>
                            <DownloadPWA></DownloadPWA>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-6 col-md-3 mb-3 text-start">
                        <h5 class="text-white mb-3">{{ $t('label_quick_link') }}</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><a href="/#home" class="nav-link p-0 text-white">{{$t('menu_home')}}</a></li>
                            <li class="nav-item mb-2"><router-link :to="{ name : 'AboutUsPage' }" class="nav-link p-0 text-white">{{$t('menu_about')}}</router-link></li>
                            <li class="nav-item mb-2"><a href="/#our-vision" class="nav-link p-0 text-white">{{$t('menu_our_vision')}}</a></li>
                            <li class="nav-item mb-2"><a href="https://blog.indiagpt.com/" class="nav-link p-0 text-white">{{$t('menu_blog')}}</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-3 mb-3 text-start">
                        <h5 class="text-white mb-3">{{ $t('label_useful_links') }}</h5>
                        <ul class="nav flex-column">
                            <li class="nav-item mb-2"><router-link :to="{ name : 'PrivacyPolicyPage' }" class="nav-link p-0 text-white">{{$t('menu_privacy_policy')}}</router-link></li>
                            <li class="nav-item mb-2"><router-link :to="{ name : 'TermsAndConditionsPage' }" class="nav-link p-0 text-white">{{$t('menu_terms_and_conditions')}}</router-link></li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-row-reverse h2 text-white gap-3">
                            <div class="p-2"><a href="https://www.facebook.com/indiagpt/" class="text-white"><i class="bi bi-facebook"></i></a></div>
                            <div class="p-2"><a href="https://twitter.com/India__GPT/" class="text-white"><i class="bi bi-twitter"></i></a></div>
                            <div class="p-2"><a href="https://www.instagram.com/indiagpt/" class="text-white"><i class="bi bi-instagram"></i></a></div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-5 flex-column flex-md-row">
                    <p class="text-center text-light fw-bolder">{{current_years}} - {{$t('label_copyright_text')}}</p>         
                    <p class="text-center text-light fw-bolder" v-html="$t('label_developer_text')"></p>         
                </div>                       
            </div>
        </section>
    </footer>
</template>

<script>
import DownloadPWA from "@/components/DownloadPWA.vue";
let get_full_year = new Date()

// @ is an alias to /src
export default {
    name: 'HomeView',
    head() {
        return {
            title: 'Hello World',
            activeIndex: 'search',
        }
    },
    data() {
        return { 
            headline: ["Unlock Limitless Conversations: Introducing"],
            isScrolled: false,
            language :  this.$store.getters.getLanguage,
            current_years: get_full_year.getFullYear()
        };
    },
    components: {
        DownloadPWA,
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 0) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
        },
        setActive(index) {
            this.activeIndex = index;
        },
        changeLanguage()
        {
            this.$store.commit('SET_LANGUAGE', this.language)
            location.reload();
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        this.activeIndex= 'search';
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
.mainNavbar
{
    transition-duration: 0.5s;
}
.mainNavbar .navbar-brand
{
    color: var(--template-color-8);
    font-family: Outfit;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainNavbar .navbar-brand img
{
    height: 50px;
    width: 50px;
}
.stickyHeader
{
    background:  var(--template-color-0);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.avatar_section
{
    padding: 10px;
    background:  var(--template-color-0);
    border-radius: 20px;
}
.avatar_section h5
{
    color: var(--template-color-2) !important;
}
.avatar_section p
{
    color: var(--template-color-18) !important;
}
.brand_logo
{
    width: auto !important;
    height: 30px !important;
}

</style>