<template>
        
        <!-- The video -->
        <video autoplay muted loop id="myVideo" poster="../assets/login-bg.png">
                <source src="../assets/login-bg-video.mp4" type="video/mp4">
        </video>
        <div class="content">
                <router-view/>
        </div>
    
</template>
<style scoped>
/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  color: var(--template-color-10);
  width: 100%;
  padding: 20px;
}
</style>