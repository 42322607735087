<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-10 col-md-6 col-lg-4">
        <div class="d-flex gap-3">
          <a href="https://apps.apple.com/ae/app/india-gpt/id6475352709" target="_blank">
            <img :src="appStore" class="img-fluid" alt="App Store" loading="lazy">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.indiagpt.application" target="_blank">
            <img :src="playStore" class="img-fluid" alt="Play Store" loading="lazy">
          </a>
          <!-- Your app content here -->
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import appStore from "../assets/App Store.webp";
import playStore from "../assets/Google Play.webp";
export default{
  data() 
  {
    return {
      appStore : appStore,
      playStore : playStore
    }
  }
} 
</script>