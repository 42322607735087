<template>
    <div v-if="$store.getters.getLanguage == 'en'">
        <TermsInEnglish />
    </div>
    <div v-if="$store.getters.getLanguage == 'hi'">
        <TermsInHindi />
    </div>
</template>
<script>
import TermsInEnglish from "@/components/TermsAndConditions_en.vue";
import TermsInHindi from "@/components/TermsAndConditions_hi.vue";

export default{
    name: "TermsAndConditionsScreen",
    components:{
        TermsInEnglish,
        TermsInHindi
    }
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>