import { createApp } from 'vue'

import App from './App.vue'

import './registerServiceWorker'
import router from './router'
import store from './store'

// meta tags manager
import { createHead } from '@vueuse/head'
const useHead = createHead()
// meta tags manager


// for multi language
import { createI18n } from 'vue-i18n';
// for multi language


// Get the stored data from local storage
const storedData = localStorage.getItem('dgptstorageunit');
if (storedData) {
  store.replaceState(JSON.parse(storedData));
}

// add global css
import "@/assets/css/global.css"
// add global css

// notification
import toast from 'vue-toast-notification';
// notification


// for animation
import AOS from 'aos'
import 'aos/dist/aos.css'
// for animation

// for UI primevue
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS

// for UI primevue

// for writer
import VueWriter from "vue-writer";
// for writer

// non www to www redirect
router.beforeEach((to, from, next) => {
  if(!/\.?www./g.test(location.toString())) {
    location.href = location.href.replace("://","://www.")
  }
  next();
});

const app = createApp(App);
app.use(store)
app.use(router)
app.use(AOS.init())
app.use(VueWriter)

// app.use(PrimeVue);
app.use(PrimeVue);

app.use(toast, {
    position: 'bottom'
})




// google login
import vue3GoogleLogin from 'vue3-google-login'
app.use(vue3GoogleLogin, {
  clientId: '202548657007-lf5h9k0rvo8ndm69pecih4in6em3svuc.apps.googleusercontent.com'
})
// google login

// multi language
if (localStorage.getItem("dgptstorageunit") !== null) {
  const i18n = createI18n({
    locale: JSON.parse(localStorage.getItem('dgptstorageunit')).language, // Default language
    fallbackLocale: 'en',
    messages: {
      en: require('./locales/en.json'),
      hi: require('./locales/hi.json'),
      // Add more languages as needed
    },
  });
  app.use(i18n);
}
else
{
  const i18n = createI18n({
    locale: 'en', // Default language
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
      en: require('./locales/en.json'),
      hi: require('./locales/hi.json'),
      // Add more languages as needed
    },
  });
  app.use(i18n);
}
// multi language

app.use(useHead)
app.mount('#app')
