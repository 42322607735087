<template>
    <div class="card bg-transparent border-0" >
        
        <div class="card-body bg-transparent">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card p-3 mb-4">
                        <div class="card-header bg-transparent border-0">
                            <h1 class="h5 mb-0">{{ $t('headline_settings') }}</h1>
                            <small>{{ $t('sub_headline_headline_settings_desc') }}</small>
                        </div>
                        <div class="card-body border-1 px-0">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center cursor_pointer">
                                    {{ $t('menu_language_change') }}
                                    <div >
                                        <select class="form-select cursor_pointer" v-model="language" @change="changeLanguage()">
                                            <option value="en">English</option>
                                            <option value="hi">हिन्दी</option>
                                        </select>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center cursor_pointer">
                                    {{ $t('menu_dark_mode') }}
                                    <!-- <div class="form-check form-switch">
                                        <label>
                                            <input class="form-check-input" type="checkbox" role="switch" value="" v-model="dark_mode" id="flexSwitchCheckDefault" @change="changeThemeMode()"> 
                                        </label>
                                    </div> -->
                                    <div>
                                        <select class="form-select cursor_pointer" v-model="theme_mode" @change="changeThemeMode()">
                                            <option value="light">{{ $t('lebel_light') }}</option>
                                            <option value="dark">{{ $t('lebel_dark') }}</option>
                                        </select>
                                    </div>
                                </li>
                                
                                <li class="list-group-item d-flex justify-content-between align-items-center cursor_pointer" @click="toggleModalReportAProblem()">
                                    {{ $t('menu_report_a_problem') }}
                                    <button class="btn btn-lg"><i class="bi bi-question"></i></button>
                                </li>
                                <router-link :to="{ name:'TermsAndConditionsScreen' }" class="list-group-item d-flex justify-content-between align-items-center cursor_pointer">
                                    {{ $t('menu_terms_of_use') }}
                                    <button class="btn btn-lg"><i class="bi bi-eye-fill"></i></button>
                                    
                                </router-link>
                                <li class="list-group-item d-flex justify-content-between align-items-center cursor_pointer" @click="toggleModalRateUs()">
                                    {{ $t('menu_rate_us') }}
                                    <button class="btn btn-lg"><i class="bi bi-star-fill"></i></button>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center cursor_pointer" @click="toggleModalDeleteAccount()">
                                    {{ $t('menu_delete_account') }}
                                    <button class="btn btn-lg"><i class="bi bi-person-x-fill"></i></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    
    <div v-if="reportAProblemModal">
        <div class="pro_modal show">
            <div class="pro_modal_dialog">
                <form @submit.prevent="submiteReport()" class="pro_modal_content">
                    <div class="pro_modal_header">
                        <h5 class="pro_modal_title">{{ $t('popup_headline_report_a_problem') }}</h5>
                        <button type="button" class="btn-close" @click="toggleModalReportAProblem"></button>
                    </div>
                    <div class="pro_modal_body">
                        <!-- Your modal content goes here -->
                        <div class="mb-3">
                            <label for="formGroupExampleInput" class="form-label">{{ $t('lebel_report_type') }}</label>
                            <input type="text" readonly class="form-control" id="formGroupExampleInput" :placeholder="$t('placeholder_report_type')" @click="toggleRProblemArea" v-model="reportForm.report_type">
                            <div class="problems-box" :class="{ 'show': isReportOptionVisible }">
                                <label class="d-flex align-items-center">
                                    <div class="flex-grow-1 me-3">
                                        <strong>{{ $t('lebel_report_type_illegal_content_title') }}</strong>
                                        <p>{{ $t('lebel_report_type_illegal_content_description') }}</p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <input type="radio" name="problem" value="Illegal content" v-model="reportForm.report_type">
                                    </div>
                                </label>
                                <label class="d-flex align-items-center">
                                    <div class="flex-grow-1 me-3">
                                        <strong>{{ $t('lebel_report_type_harassment_title') }}</strong>
                                        <p>{{ $t('lebel_report_type_harassment_description') }}</p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <input type="radio" name="problem" value="Harassment" v-model="reportForm.report_type">
                                    </div>
                                </label>
                                <label class="d-flex align-items-center">
                                    <div class="flex-grow-1 me-3">
                                        <strong>{{ $t('lebel_report_type_spam_or_phishing_links_title') }}</strong>
                                        <p>{{ $t('lebel_report_type_spam_or_phishing_links_description') }}</p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <input type="radio" name="problem" value="Spam or phishing links" v-model="reportForm.report_type">
                                    </div>
                                </label>
                            </div>
                            <span class="text-danger w-100 text-end" v-if="reportForm.error && v$.reportForm.report_type.$error"> {{ v$.reportForm.report_type.$errors[0].$message }}</span>


                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">{{$t('lebel_report_description')}}</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" :placeholder="$t('placeholder_report_description')" rows="5" v-model="reportForm.report_description"></textarea>
                            <span class="text-danger w-100 text-end" v-if="reportForm.error && v$.reportForm.report_description.$error"> {{ v$.reportForm.report_description.$errors[0].$message }}</span>
                        </div>
                    </div>
                    <div class="pro_modal_footer">
                        <button type="button" class="theme_button outline" @click="toggleModalReportAProblem">{{$t('button_cancel')}}</button>
                        <div v-if="reportForm.loading">
                            <button type="button" disabled class="theme_button disabled">Loading .....</button>
                        </div>
                        <div v-else>
                            <button type="submit" class="theme_button">{{$t('button_submit')}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    

    <div v-if="rateUsModal">
        <div class="pro_modal show">
            <div class="pro_modal_dialog">
                <form class="pro_modal_content" @submit.prevent="submitRateUsForm()">
                    <div class="pro_modal_header">
                        <h5 class="pro_modal_title">{{$t('popup_headline_rate_us')}}</h5>
                        <button type="button" class="btn-close" @click="toggleModalRateUs"></button>
                    </div>
                    <div class="pro_modal_body">
                        <!-- Your modal content goes here -->
                        <div class="mb-3 text-center">
                            <!-- star rating -->
                                <div class="stars">
                                    <input class="star star-5" id="star-5" type="radio" name="star" value="5" v-model="rateForm.rate" />
                                    <label class="star star-5" for="star-5">
                                    <i class="bi bi-star-fill icon"></i>
                                    </label>

                                    <input class="star star-4" id="star-4" type="radio" name="star" value="4" v-model="rateForm.rate" />
                                    <label class="star star-4" for="star-4">
                                    <i class="bi bi-star-fill icon"></i>
                                    </label>

                                    <input class="star star-3" id="star-3" type="radio" name="star" value="3" v-model="rateForm.rate" />
                                    <label class="star star-3" for="star-3">
                                    <i class="bi bi-star-fill icon"></i>
                                    </label>

                                    <input class="star star-2" id="star-2" type="radio" name="star" value="2" v-model="rateForm.rate" />
                                    <label class="star star-2" for="star-2">
                                    <i class="bi bi-star-fill icon"></i>
                                    </label>

                                    <input class="star star-1" id="star-1" type="radio" name="star" value="1" v-model="rateForm.rate" />
                                    <label class="star star-1" for="star-1">
                                    <i class="bi bi-star-fill icon"></i>
                                    </label>
                                    <p>{{$t('popup_sub_headline_rate_us')}}</p>
                                </div>
                                <span class="text-danger w-100 d-block" v-if="rateForm.error && v$.rateForm.rate.$error"> Please select start between 1 to 5</span>

                        </div>
                    </div>
                    <div class="pro_modal_footer">
                        <button type="button" @click="toggleModalRateUs" class="theme_button outline">{{$t('button_not_now')}}</button>

                        <div v-if="rateForm.loading">
                            <button type="button" disabled class="theme_button disabled">Loading .....</button>
                        </div>
                        <div v-else>
                            <button type="submit" class="theme_button">{{$t('button_rate_us')}}</button>
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div v-if="deleteAccountModal">
        <div class="pro_modal show">
            <div class="pro_modal_dialog">
                <div class="pro_modal_content">
                <div class="pro_modal_header">
                    <h5 class="pro_modal_title">{{$t('popup_headline_delete_account')}}</h5>
                    <button type="button" class="btn-close" @click="toggleModalDeleteAccount"></button>
                </div>
                <div class="pro_modal_body">
                    <!-- Your modal content goes here -->
                    <div class="px-3 ">
                        <!-- star rating -->
                        <ul>
                            <li>{{$t('popup_list_delete_account_line_1')}}</li>
                            <li>{{$t('popup_list_delete_account_line_2')}}</li>
                            <li>{{$t('popup_list_delete_account_line_3')}}</li>
                        </ul>
                        <!-- <p class="mb-3 text-warning">You may only delete your account if you have logged in within the last 10 minutes. Please log in again, then return here to continue.</p> -->
                    </div>
                </div>
                <div class="pro_modal_footer">
                    <button type="button" class="theme_button outline" @click="toggleModalDeleteAccount">{{$t('button_cancel')}}</button>

                    <div v-if="deleteAccountLoading">
                        <button type="button" disabled class="theme_button disabled">Loading .....</button>
                    </div>
                    <div v-else>
                        <button type="button" class="theme_button" @click="deleteMyAccount()">{{ $t('button_delete_my_account') }}</button>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength,  maxLength } from '@vuelidate/validators';
import axios from 'axios';


// import RatingUs from "@/components/RatingUs.vue";
// import ReportAProblem from "@/components/ReportAProblem.vue";
// import DeleteAccount from "@/components/DeleteAccount.vue"


export default{
    name:"SettingsScreen",
    data(){
        return {
            reportForm :{
                report_type : "",
                report_description : "",
                error : false,
                loading: false
            },
            rateForm : {
                rate : "",
                error : false,
                loading: false
            },
            
            theme_mode :  this.$store.getters.getThemeMode,
            language :  this.$store.getters.getLanguage,
            isReportOptionVisible: false,
            reportAProblemModal : false,
            rateUsModal : false,
            deleteAccountModal : false,
            deleteAccountLoading : false,
        }
    },
    setup() {      
        return {
            v$: useVuelidate(),
        };
    },
    validations() {
        return {
            reportForm : {
                report_type: {
                    required
                },
                report_description: {
                    required
                }
            },
            rateForm : {
                rate : { 
                    required,
                    minLength: minLength(1),maxLength: maxLength(1),
                },
            },
        }
    },
    methods:
    {
        submiteReport()
        {
            this.v$.reportForm.$touch();
            if (!this.v$.reportForm.$invalid) {
                this.reportForm.loading = true
                const formData = axios.toFormData(this.reportForm)
                // alert("validated");
                this.clearAllConversationsModal_loading = true
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/publish-report/`;
                const headers = { 
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.post(API_URL, formData, {headers})
                .then((response) => {
                    this.reportForm.error = false
                    if(response.status == 204)
                    {
                        // this.$toast.error(response.data.message, {
                        //     icon: "dangerous",
                        // }); 
                    }
                    else if(response.status == 200)
                    {
                        this.$toast.success(response.data.message); 
                        this.reportForm.report_type = "";
                        this.reportForm.report_description = "";
                        this.isReportOptionVisible = false
                        this.reportAProblemModal = false
                    }
                    this.reportForm.loading = false
                    console.log(response.data);
                })
                .catch((error) => {
                // Handle errors
                    console.log(error);
                    this.$toast.error("Ops something is wrong. try again later", {
                        icon: "dangerous",
                    }); 
                    this.reportForm.loading = false
                }); 
            } else {
                // alert("Not validated");
                this.reportForm.error = true
            } 
        },

        submitRateUsForm()
        {
            this.v$.rateForm.$touch();
            if (!this.v$.rateForm.$invalid) {
                this.rateForm.error = false
                // alert("validated");

                this.rateForm.loading = true
                const formData = axios.toFormData(this.rateForm)
                // alert("validated");
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/rating-us/`;
                const headers = { 
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.post(API_URL, formData, {headers})
                .then((response) => {
                    this.rateForm.error = false
                    if(response.status == 204)
                    {
                        // this.$toast.error(response.data.message, {
                        //     icon: "dangerous",
                        // }); 
                    }
                    else if(response.status == 200)
                    {
                        this.$toast.success("Thank you for rating us"); 
                        this.rateForm.rate = "";
                        this.rateUsModal = false
                    }
                    this.rateForm.loading = false
                    console.log(response.data);
                })
                .catch((error) => {
                // Handle errors
                    console.log(error);
                    this.$toast.error("Ops something is wrong. try again later", {
                        icon: "dangerous",
                    }); 
                    this.rateForm.loading = false
                }); 

            } else {
                // alert("Not validated");
                this.rateForm.error = true
            } 
        },



        // delete my account
        deleteMyAccount()
        {
            this.deleteAccountLoading = true
            const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/delete-my-account/`;
            const headers = { 
                "Authorization": `Bearer ${this.$store.getters.getToken}`,
            };

            axios.delete(API_URL, {headers})
            .then((response) => {
                
                if(response.status == 204)
                {
                    // this.$toast.error(response.data.message, {
                    //     icon: "dangerous",
                    // }); 
                }
                else if(response.status == 200)
                {
                    this.$toast.success(response.data.message); 
                    this.$router.push({ name: 'LoginView' })                    
                }
                console.log(response.data);
                this.deleteAccountModal = false
                this.deleteAccountLoading = false
            })
            .catch((error) => {
            // Handle errors
                console.log(error);
                this.$toast.error("Ops something is wrong. try again later", {
                    icon: "dangerous",
                }); 
                
                this.deleteAccountLoading = false
            }); 
        },
        // delete my account
        
        
        changeLanguage()
        {
            this.$store.commit('SET_LANGUAGE', this.language)
            location.reload();
        },
        changeThemeMode()
        {
            this.$store.commit('SET_THEME_MODE', this.theme_mode)
            location.reload();
        },
        
        toggleModalReportAProblem()
        {
            this.reportAProblemModal = !this.reportAProblemModal
        },

        toggleRProblemArea()
        {
            this.isReportOptionVisible = !this.isReportOptionVisible
        },

        toggleModalRateUs()
        {
            this.rateUsModal = !this.rateUsModal
            // this.$refs.modalRef.showModal();
        },
        
        // delete accounts
        toggleModalDeleteAccount()
        {
            this.deleteAccountModal = !this.deleteAccountModal
        },
        // delete accounts        
    },
}
</script>

<style scoped>
.problems-box
{
    background: var(--template-color-22);
    padding: 30px;
    margin: 10px 0px;
    display: none;
}

.problems-box.show
{
    display: block;
}

[data-bs-theme="dark"] .problems-box
{
    background: var(--template-color-2);
}

/* for rating */
div.stars {
    width: auto;
    display: inline-block;
}

.mt-200 {
    margin-top: 200px;
}

input.star {
    display: none;
}

label.star {
    float: right;
    padding: 10px;
    font-size: 36px;
    color: var(--template-color-23);
    transition: all .2s;
}

input.star:checked~label.star .icon {
    content: '&#xF588';
    color: var(--template-color-24);
    transition: all .25s;
}

input.star-5:checked~label.star .icon {
    color: var(--template-color-25);
    text-shadow: 0 0 20px var(--template-color-27);
}

input.star-1:checked~label.star:before {
    color: var(--template-color-26);
}

label.star:hover {
    transform: rotate(-15deg) scale(1.3);
}

label.star .icon {
    content: '&#xF588';
}
/* for rating */
</style>