<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                
                <h1 class="h4"><dt>इंडिया जीपीटी नियम और शर्तें  प्रभावी </dt> </h1>
                               
            </div>
            <div class="card-body p-4">
                <h1 class="h5">तिथि: 1 जनवरी, 2024</h1>
                <hr>
                <div>
                    <dl>
                        <dd>इंडिया जीपीटी में आपका स्वागत है! ये नियम और शर्तें इंडिया जीपीटी की वेबसाइट और सेवाओं के उपयोग के लिए नियम और विनियमों को रेखांकित करती हैं। इस वेबसाइट तक पहुँचने और हमारी सेवाओं का उपयोग करके, आप इन नियमों और शर्तों को पूरी तरह से स्वीकार करते हैं। यदि आप इस पृष्ठ पर वर्णित सभी नियमों और शर्तों को स्वीकार नहीं करते हैं, तो कृपया इंडिया जीपीटी की वेबसाइट का उपयोग जारी न रखें।</dd>
                        <dt>1. सेवा का उपयोग</dt>
                        <dd>
                            <ul>
                                <li>आप सहमत हैं कि इंडिया जीपीटी की सेवाओं का उपयोग केवल कानूनी उद्देश्यों के लिए करेंगे और ऐसे तरीके से जो किसी अन्य के अधिकारों का उल्लंघन नहीं करता है, या किसी अन्य के सेवाओं के उपयोग और आनंद को प्रतिबंधित या बाधित नहीं करता है।</li>
                                <li>आपको हमारी सेवाओं का उपयोग करके किसी भी कंप्यूटर वायरस, मैलवेयर, या किसी अन्य हानिकारक कोड को प्रेषित या अपलोड करने या हमारी सेवाओं या वेबसाइट के संचालन को बाधित या क्षति पहुँचाने वाली किसी भी गतिविधि में लिप्त होने की अनुमति नहीं है।</li>
                            </ul>
                        </dd>
                        <dt>2. बौद्धिक संपदा अधिकार </dt>
                        <dd>
                            <ul>
                                <li>इंडिया जीपीटी द्वारा प्रदान की गई सामग्री, सामग्रियों और सेवाओं में सभी बौद्धिक संपदा अधिकार हमारे द्वारा स्वामित्व या लाइसेंस प्राप्त हैं। आप हमारी स्पष्ट लिखित अनुमति के बिना इंडिया जीपीटी द्वारा प्रदान की गई किसी भी सामग्री की प्रतिलिपि नहीं बना सकते, पुनरुत्पादित नहीं कर सकते, वितरित नहीं कर सकते, या डेरिवेटिव कार्य नहीं बना सकते।</li>
                            </ul>
                        </dd>
                        <dt>3. तृतीय-पक्ष एपीआई और डेटा सटीकता </dt>
                        <dd>
                            <ul>
                                <li>इंडिया जीपीटी हमारी सेवाओं को बढ़ाने और आपको सबसे सटीक, अप-टू-डेट जानकारी और कार्यक्षमताएं प्रदान करने के लिए विभिन्न तृतीय-पक्ष एपीआई के साथ एकीकृत करता है। ये तृतीय-पक्ष सेवाएं डेटा विश्लेषण, संचार प्लेटफ़ॉर्म और अन्य आर्टिफिशियल इंटेलिजेंस तकनीकों सहित हो सकती हैं, जो हमारी पेशकशों को पूरक करती हैं।</li>
                                <li>जब आप तृतीय-पक्ष सेवाओं द्वारा संचालित सेवाओं के साथ बातचीत करते हैं, तो ऐसे तृतीय पक्ष हमसे स्वतंत्र रूप से आपके बारे में जानकारी एकत्र कर सकते हैं। इन तृतीय पक्षों द्वारा आपकी जानकारी का संग्रह, उपयोग, और साझाकरण उनकी संबंधित गोपनीयता नीतियों और सेवा की शर्तों के अधीन है, जिनकी समीक्षा करने के लिए हम आपको प्रोत्साहित करते हैं। </li>
                                <li>जबकि हम प्रतिष्ठित और विश्वसनीय तृतीय-पक्ष सेवाओं का चयन करने का प्रयास करते हैं, इंडिया जीपीटी इन तृतीय-पक्ष एपीआई के माध्यम से प्रदान की गई जानकारी की सटीकता, पूर्णता, या विश्वसनीयता की गारंटी नहीं दे सकता। इन सेवाओं से प्राप्त जानकारी "जैसी है" प्रदान की जाती है बिना किसी प्रतिनिधित्व या समर्थन के और बिना किसी प्रकार की वारंटी के, चाहे वह व्यक्त या निहित हो।</li>
                                <li>इंडिया जीपीटी तृतीय-पक्ष एपीआई के माध्यम से प्रदान की गई सामग्री में किसी भी त्रुटियों, अशुद्धियों, या चूकों के लिए न ही किसी भी प्रकार के नुकसान या क्षति के लिए जिम्मेदार होगा जो किसी भी सामग्री के पोस्ट, प्रेषित, या अन्यथा उपलब्ध कराए जाने के कारण होता है तृतीय-पक्ष सेवाओं के साथ एकीकृत हमारे मंच के माध्यम से।</li>
                             </ul>
                        </dd>
                        <dt>4. उपयोगकर्ता-जनित सामग्री </dt>
                        <dd>
                            <ul>
                                <li>आप इंडिया जीपीटी को प्रदान या अपलोड की गई सामग्री में सभी अधिकारों को बनाए रखते हैं, और आप इसमें शामिल पाठ, चित्र और किसी भी अन्य सामग्रियों के लिए एकमात्र जिम्मेदार हैं। </li>
                                <li>इंडिया जीपीटी को सामग्री प्रदान करके, आप हमें इंडिया जीपीटी द्वारा प्रदान की जाने वाली सेवाओं के संबंध में उस सामग्री का उपयोग करने, पुनरुत्पादित करने, वितरित करने और प्रदर्शित करने के लिए एक गैर-विशिष्ट, विश्वव्यापी, रॉयल्टी-मुक्त लाइसेंस प्रदान करते हैं।</li>
                            </ul>
                        </dd>
                        <dt>5. गोपनीयता </dt>
                        <dd>
                            <ul>
                                <li>इंडिया जीपीटी की सेवाओं का आपका उपयोग हमारी गोपनीयता नीति के अधीन है, जो बताती है कि हम आपकी व्यक्तिगत जानकारी को कैसे एकत्र करते हैं, उपयोग करते हैं, और सुरक्षित रखते हैं।</li>
                            </ul>
                        </dd>
                        <dt>6. दायित्व की सीमा </dt>
                        <dd>
                            <ul>
                                <li>इंडिया जीपीटी हमारी सेवाओं के उपयोग से उत्पन्न किसी भी अप्रत्यक्ष, आकस्मिक, विशेष, परिणामी, या दंडात्मक क्षतियों के लिए जिम्मेदार नहीं होगा।</li>
                            </ul>
                        </dd>
                        <dt>7. क्षतिपूर्ति </dt>
                        <dd>
                            <ul>
                                <li>आप सहमत हैं कि इंडिया जीपीटी और इसके संबद्धों, अधिकारियों, एजेंटों, और कर्मचारियों को किसी भी दावे या मांग से, उचित वकीलों की फीस सहित, हमारी सेवाओं के आपके उपयोग, इन नियमों और शर्तों के आपके उल्लंघन, या किसी अन्य के अधिकारों के आपके उल्लंघन के कारण या उससे उत्पन्न किसी भी तृतीय पक्ष द्वारा की गई किसी भी दावे या मांग से क्षतिपूर्ति और हानि से मुक्त रखेंगे।</li>
                            </ul>
                        </dd>
                        <dt>8. समाप्ति </dt>
                        <dd>
                            <ul>
                                <li>हम किसी भी कारण से, बिना किसी पूर्व सूचना या दायित्व के, तत्काल प्रभाव से आपकी हमारी सेवाओं तक पहुँच को समाप्त या निलंबित कर सकते हैं, जिसमें बिना किसी सीमा के शामिल हैं, यदि आप इन नियमों और शर्तों का उल्लंघन करते हैं।</li>
                            </ul>
                        </dd>
                        <dt>9. शासन विधि </dt>
                        <dd>
                            <ul>
                                <li>इन नियमों और शर्तों को भारत के कानूनों के अनुसार शासित किया जाएगा और उनके अनुसार व्याख्या की जाएगी, और आप किसी भी विवादों के समाधान के लिए भारत में स्थित अदालतों के अनन्य अधिकार क्षेत्र के अधीन होते हैं।</li>
                            </ul>
                        </dd>
                        <dt>10. नियमों और शर्तों में परिवर्तन </dt>
                        <dd>
                            <ul>
                                <li>हम किसी भी समय इन नियमों और शर्तों में संशोधन करने का अधिकार सुरक्षित रखते हैं। किसी भी परिवर्तन को इस पृष्ठ पर पोस्ट किया जाएगा, और आपका किसी भी परिवर्तन के बाद हमारी सेवाओं का निरंतर उपयोग नई नियमों और शर्तों की आपकी स्वीकृति को दर्शाता है।</li>
                            </ul>
                        </dd>
                        <dt>11. हमसे संपर्क करें</dt>
                        <dd>
                            <ul>
                                <li>यदि आपके पास इन नियमों और शर्तों के बारे में कोई प्रश्न या चिंताएं हैं, तो कृपया हमसे संपर्क करें: <a class="text-secondary" style="text-decoration: none;" href="mailto:info@indiagpt.com">info@indiagpt.com</a></li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "TermsAndConditionsScreen"
}
</script>

<style>
</style>