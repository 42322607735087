<template>
    <router-view/>
</template>
<script>
import { useHead } from "@vueuse/head";
export default{
  name: "App",
  setup()
  {
    useHead({
        title: 'India GPT'
    })
  }
  

}
</script>
