<template>
    <section class="container">
        <div class="card border-0">
            <div class="card-header border-0 p-4">
                
                <h1 class="h4"><dt>इंडिया जीपीटी गोपनीयता नीति</dt></h1>
                               
            </div>
            <div class="card-body p-4">
                <h1 class="h5">प्रभावी तिथि: 1.1.24</h1>
                <hr>
                <div>
                    <dl>
                        <dd>इंडिया जीपीटी में आपका स्वागत है! हम आपकी गोपनीयता का सम्मान करते हैं और आपके व्यक्तिगत डेटा की सुरक्षा के लिए प्रतिबद्ध हैं। यह गोपनीयता नीति समझाती है कि हम अपने उपयोगकर्ताओं के बारे में जानकारी कैसे इकट्ठा करते हैं, उपयोग करते हैं, साझा करते हैं, और सुरक्षित रखते हैं और आपकी व्यक्तिगत जानकारी के संबंध में आपके अधिकारों की रूपरेखा तैयार करती है।</dd>
                         <dt>1. जानकारी हम एकत्र करते हैं</dt>
                        <dd>
                            <p>हम अपने सभी उपयोगकर्ताओं को बेहतर सेवाएं प्रदान करने के लिए जानकारी एकत्र करते हैं। हम जो जानकारी एकत्र करते हैं उसमें शामिल हैं:</p>
                            <ul>
                                <li>व्यक्तिगत पहचान जानकारी: नाम, ईमेल पता, फोन नंबर, आदि, जब आप हमारी सेवाओं, समाचार पत्रों के लिए साइन अप करते हैं, या हमारे ऑनलाइन मंचों में भाग लेते हैं।</li>
                                <li>उपयोग की जानकारी: इंडिया जीपीटी का उपयोग करने के विवरण, जिसमें हमारे मंच और एआई प्रतिक्रियाओं के साथ बातचीत का डेटा शामिल है।</li>
                                <li>तकनीकी डेटा: आईपी पता, ब्राउज़र प्रकार और संस्करण, समय क्षेत्र सेटिंग और स्थान, ब्राउज़र प्लग-इन प्रकार और संस्करण, ऑपरेटिंग सिस्टम और प्लेटफ़ॉर्म, और अन्य प्रौद्योगिकी जो आप इंडिया जीपीटी तक पहुँचने के लिए उपयोग करते हैं।</li>
                                <li>कुकीज़ और इसी तरह की तकनीकें: हम आपकी सेवा पर गतिविधि को ट्रैक करने और आपके अनुभव को बेहतर और वैयक्तिकृत करने के लिए कुकीज़ और इसी तरह की ट्रैकिंग तकनीकों का उपयोग करते हैं।</li>
                            </ul>
                        </dd>
                        <dt>2. हम आपकी जानकारी का उपयोग कैसे करते हैं</dt>
                        <dd>
                            <p>हम एकत्र की गई जानकारी का उपयोग करते हैं:</p>
                            <ul>
                                <li>हमारी सेवाओं को प्रदान करने, बनाए रखने, और सुधारने के लिए।</li>
                                <li>नए उत्पादों, सेवाओं, विशेषताओं, और कार्यक्षमताओं का विकास करने के लिए।</li>
                                <li>आपसे संवाद करने के लिए, जिसमें आपको अपडेट, सहायता संदेश, और प्रशासनिक संदेश भेजना शामिल है।</li>
                                <li>उपयोगकर्ता अनुभव में सुधार के लिए आप हमारी सेवाओं का उपयोग कैसे करते हैं इसे समझने और विश्लेषण करने के लिए।</li>
                                <li>कानूनी अनुपालन सुनिश्चित करने और हमारे मंच को दुरुपयोग से बचाने के लिए।</li>
                            </ul>
                        </dd>
                        <dt>3. आपकी जानकारी साझा करना</dt>
                        <dd>
                            <p>हम इंडिया जीपीटी के बाहर कंपनियों, संगठनों, या व्यक्तियों के साथ आपकी व्यक्तिगत जानकारी साझा नहीं करते हैं, सिवाय निम्नलिखित मामलों के:</p>
                            <ul>
                                <li>आपकी सहमति के साथ: हम इंडिया जीपीटी के बाहर व्यक्तिगत जानकारी साझा करेंगे जब हमारे पास आपकी सहमति होगी।</li>
                                <li>बाहरी प्रसंस्करण के लिए: हम अपने निर्देशों के आधार पर हमारे लिए इसे संसाधित करने के लिए हमारे सहयोगी या अन्य विश्वसनीय व्यवसायों या व्यक्तियों को व्यक्तिगत जानकारी प्रदान करते हैं, हमारी गोपनीयता नीति और किसी अन्य उपयुक्त गोपनीयता और सुरक्षा उपायों के अनुपालन में।</li>
                                <li>कानूनी कारणों के लिए: हम किसी भी लागू कानून, विनियमन, कानूनी प्रक्रिया, या प्रवर्तनीय सरकारी अनुरोध को पूरा करने के लिए जानकारी की पहुँच, उपयोग, संरक्षण, या प्रकटीकरण आवश्यक होने का अच्छा-विश्वास विश्वास होने पर इंडिया जीपीटी के बाहर कंपनियों, संगठनों, या व्यक्तियों के साथ व्यक्तिगत जानकारी साझा करेंगे।</li>
                            </ul>
                        </dd>
                        <dt>4. आपके डेटा सुरक्षा अधिकार </dt>
                        <dd>
                            <p>आपके पास अधिकार है:</p>
                            <ul>
                                <li>अपनी व्यक्तिगत जानकारी तक पहुँचने, सही करने, अपडेट करने, या उसे हटाने के लिए अनुरोध करने का।</li>
                                <li>अपनी व्यक्तिगत जानकारी के प्रसंस्करण का विरोध करने के लिए, हमें अपनी व्यक्तिगत जानकारी के प्रसंस्करण को सीमित करने के लिए कहने के लिए, या अपनी व्यक्तिगत जानकारी की पोर्टेबिलिटी का अनुरोध करने के लिए।</li>
                                <li>अपनी सहमति को किसी भी समय वापस लेने के लिए यदि हमने आपकी सहमति के साथ आपकी व्यक्तिगत जानकारी एकत्र की और संसाधित की है।</li>
                                <li>हमारे द्वारा आपकी व्यक्तिगत जानकारी के संग्रहण और उपयोग के बारे में एक डेटा सुरक्षा प्राधिकरण से शिकायत करने के लिए।</li>
                            </ul>
                        </dd>
                        <dt>5. डेटा सुरक्षा</dt>
                        <dd>
                            <p>हम आपके बारे में एकत्र और संसाधित की गई व्यक्तिगत जानकारी की सुरक्षा के लिए उपयुक्त तकनीकी और संगठनात्मक उपायों का उपयोग करते हैं। हम जो उपाय उपयोग करते हैं वे आपकी व्यक्तिगत जानकारी के प्रसंस्करण के जोखिम के अनुरूप सुरक्षा का एक स्तर प्रदान करने के लिए डिज़ाइन किए गए हैं।</p>
                        </dd>
                        <dt>6. थर्ड-पार्टी एपीआई का उपयोग</dt>
                        <dd>
                            <p>इंडिया जीपीटी हमारी सेवाओं को बढ़ाने और आपको सबसे सटीक, अद्यतन जानकारी और कार्यक्षमताएं प्रदान करने के लिए विभिन्न थर्ड-पार्टी एपीआई के साथ एकीकृत करती है। ये थर्ड-पार्टी सेवाएं डेटा विश्लेषिकी, संचार मंच, और अन्य एआई प्रौद्योगिकियों सहित हो सकती हैं जो हमारी पेशकशों के पूरक हैं।</p>
                            
                        </dd>
                        <dt>थर्ड-पार्टी सेवाएं और डेटा सटीकता</dt>
                        <dd>
                            <ul>
                                <li>डेटा संग्रहण और उपयोग: जब आप थर्ड-पार्टीज़ द्वारा संचालित सेवाओं के साथ बातचीत करते हैं, तो ऐसी थर्ड-पार्टीज़ हमसे स्वतंत्र रूप से आपके बारे में जानकारी एकत्र कर सकती हैं। इन थर्ड-पार्टीज़ द्वारा आपकी जानकारी का संग्रहण, उपयोग, और साझाकरण उनकी संबंधित गोपनीयता नीतियों और सेवा शर्तों के अधीन होता है, जिन्हें हम आपको समीक्षा करने की सलाह देते हैं।</li>
                                <li>गलत जानकारी के लिए दायित्व: जबकि हम प्रतिष्ठित और विश्वसनीय थर्ड-पार्टी सेवाओं का चयन करने का प्रयास करते हैं, इंडिया जीपीटी इन थर्ड-पार्टी एपीआई के माध्यम से प्रदान की गई जानकारी की सटीकता, पूर्णता, या विश्वसनीयता की गारंटी नहीं दे सकती। इन सेवाओं से प्राप्त जानकारी किसी भी प्रकार की प्रतिनिधित्व या समर्थन के बिना और किसी भी प्रकार की वारंटी के बिना "जैसी है" प्रदान की जाती है, चाहे वह व्यक्त की गई हो या निहित हो।</li>
                                <li>दायित्व की सीमा: इंडिया जीपीटी थर्ड-पार्टी एपीआई के माध्यम से प्रदान की गई सामग्री में किसी भी त्रुटियों, अशुद्धियों, या चूकों के लिए या किसी भी प्रकार के नुकसान या क्षति के लिए जिम्मेदार नहीं होगी जो किसी भी सामग्री के पोस्ट, प्रेषित, या अन्यथा उपलब्ध कराए जाने के परिणामस्वरूप होती है।</li>
                            </ul>
                            <p>हम थर्ड-पार्टी सेवाओं के माध्यम से प्रदान की गई जानकारी पर निर्भर होने से पहले सावधानी बरतने और अपनी जाँच करने की सलाह देते हैं। थर्ड-पार्टी एपीआई से प्राप्त जानकारी की सटीकता से संबंधित किसी भी चिंताओं के लिए, कृपया अपनी पूछताछ संबंधित थर्ड-पार्टी सेवा प्रदाताओं को निर्देशित करें।</p>
                        </dd>
                        <dt>7. अंतरराष्ट्रीय स्थानांतरण</dt>
                        <dd>
                            <p>आपकी जानकारी, जिसमें व्यक्तिगत डेटा शामिल है, आपके राज्य, प्रांत, देश, या अन्य सरकारी क्षेत्राधिकार के बाहर स्थित कंप्यूटरों पर स्थानांतरित की जा सकती है - और उन पर बनाए रखी जा सकती है - जहां डेटा सुरक्षा कानून आपके क्षेत्राधिकार से भिन्न हो सकते हैं।</p>
                        </dd>
                        <dt>8. बच्चों की गोपनीयता</dt>
                        <dd>
                            <p>हमारी सेवा 13 वर्ष से कम उम्र के किसी को भी संबोधित नहीं करती है। हम 13 वर्ष से कम उम्र के किसी भी व्यक्ति से जानबूझकर व्यक्तिगत रूप से पहचान योग्य जानकारी एकत्र नहीं करते हैं। यदि आप एक माता-पिता या अभिभावक हैं और आपको पता चलता है कि आपके बच्चे ने हमें व्यक्तिगत डेटा प्रदान किया है, तो कृपया हमसे संपर्क करें।</p>
                        </dd>
                        <dt>9. इस गोपनीयता नीति में परिवर्तन</dt>
                        <dd>
                            <p>हम समय-समय पर अपनी गोपनीयता नीति को अपडेट कर सकते हैं। हम इस पृष्ठ पर नई गोपनीयता नीति पोस्ट करके आपको किसी भी परिवर्तन की सूचना देंगे। आपको किसी भी परिवर्तन के लिए इस गोपनीयता नीति की नियमित रूप से समीक्षा करने की सलाह दी जाती है।</p>
                        </dd>
                        <dt>10. हमसे संपर्क करेंयदि </dt>
                        <dd>
                            <p>आपके पास इस गोपनीयता नीति के बारे में कोई प्रश्न हैं, तो कृपया हमसे ईमेल पर संपर्क करें: <a class="text-secondary" style="text-decoration: none;" href="mailto:info@indiagpt.com">info@indiagpt.com</a></p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    name: "PrivacyPolicyScreen"
}
</script>

<style>
</style>