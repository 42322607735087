<template>
    <div v-if="$store.getters.getLanguage == 'en'">
        <AboutInEnglish />
    </div>
    <div v-if="$store.getters.getLanguage == 'hi'">
        <AboutInHindi />
    </div>
</template>
<script>
import AboutInEnglish from "@/components/AboutUs_en.vue";
import AboutInHindi from "@/components/AboutUs_hi.vue";

export default{
    name: "AboutUs",
    components:{
        AboutInEnglish,
        AboutInHindi
    }
}
</script>

<style>
dl dt {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd {
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul,
ol {
    margin-left: 20px;
}
</style>