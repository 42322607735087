<template>
    <div v-if="$store.getters.getLanguage == 'en'">
        <PrivacyInEnglish />
    </div>
    <div v-if="$store.getters.getLanguage == 'hi'">
        <PrivacyInHindi />
    </div>
</template>
<script>
import PrivacyInEnglish from "@/components/PrivacyPolicy_en.vue";
import PrivacyInHindi from "@/components/PrivacyPolicy_hi.vue";

export default{
    name: "PrivacyPolicyScreen",
    components:{
        PrivacyInEnglish,
        PrivacyInHindi
    }
}
</script>

<style>
dl dt 
{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}
dl dd
{
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}
ul, ol
{
    margin-left: 20px;
}
</style>