<template>
    <!-- about -->
    <section id="aboutuspaage" ref="section2" class="py-5">
        <div class="container py-1">
            <div class="row align-items-center justify-content-center ">

                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <h3 class="sub-headline-decor-small">इंडिया जीपीटी – हमारे बारे में:</h3>
                                </div>
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 91 91"
                                        fill="none">
                                        <g clip-path="url(#clip0_817_22135)">
                                            <path
                                                d="M30.9126 14.0716C34.3213 9.76431 40.7406 7.63718 45.1162 7.52723C57.386 7.29214 62.501 22.7432 62.8953 28.8857L68.5753 30.6071C67.2671 11.7777 55.6836 1.90039 44.6005 1.90039C34.2076 1.8966 24.6109 9.53302 20.7434 20.7298C15.4616 35.6576 18.9272 50.0091 25.4033 61.494C24.8384 62.241 24.4971 63.5567 24.6109 64.8193C24.7208 68.2659 27.6177 71.1362 31.087 71.1362H31.3145C32.173 71.1116 33.0178 70.9156 33.7993 70.5598C34.5809 70.2039 35.2834 69.6954 35.8656 69.0641C36.4478 68.4328 36.8979 67.6915 37.1894 66.8838C37.481 66.076 37.608 65.2181 37.5632 64.3605C37.5632 60.9139 34.6095 58.0512 31.1439 58.0512H30.9126C30.6851 58.0512 30.3477 58.0512 30.0595 58.1611C25.3465 50.1228 23.3558 41.5081 24.099 32.2148C24.5502 25.2078 26.8252 19.1221 30.9126 14.0716Z"
                                                fill="url(#paint0_linear_817_22135)" />
                                            <path
                                                d="M3.53245 79.2372C7.33929 84.9778 15.1236 88.6481 23.6435 88.6481C25.9147 88.6481 28.3034 88.4813 30.6315 87.905C45.4038 85.0347 56.5968 76.0788 63.0161 62.8762L63.0692 62.7586C66.3642 62.4705 68.9197 59.5395 68.7529 56.036C68.5785 52.5325 65.7385 49.7229 62.2767 49.7229H62.0455C58.4699 49.8404 55.683 52.8814 55.7968 56.5024C55.9105 58.3376 56.6537 59.8315 57.6699 60.9197C53.6962 68.7305 47.7319 74.4673 38.6964 79.2941C32.6183 82.5056 26.199 83.7152 19.9504 82.8545C14.7255 82.1113 10.638 79.7491 8.13554 75.965C4.38937 70.2206 4.04812 64.0251 7.16866 57.8257C9.44745 53.3477 12.8524 50.0755 15.1274 48.4641C14.5586 46.9739 13.8761 44.4487 13.5349 42.5566C-3.28875 54.7241 -1.57871 71.3695 3.53245 79.2372Z"
                                                fill="url(#paint1_linear_817_22135)" />
                                            <path
                                                d="M88.9738 76.7055C92.3787 69.816 92.0981 58.2211 83.4038 48.287L83.3507 48.4007C74.5503 37.9509 61.5941 32.2103 46.818 32.2103H44.8843C43.925 30.087 41.7106 28.7637 39.2081 28.7637H39.0375C35.462 28.7637 32.6751 31.869 32.7926 35.4825C32.9064 38.9253 35.8032 41.7994 39.2688 41.7994H39.5494C40.7824 41.7468 41.9741 41.3396 42.9816 40.6267C43.989 39.9139 44.7695 38.9255 45.2293 37.7803H47.3337C56.0886 37.7803 64.3772 40.3624 71.9378 45.4129C77.7277 49.2614 81.8796 54.3119 84.2076 60.3407C86.2476 65.2774 86.1376 70.1004 84.037 74.1234C80.7989 80.4365 75.3427 83.7694 68.131 83.7694C63.5848 83.7694 59.1561 82.3323 56.8811 81.301C55.5199 82.4423 53.2449 84.3381 51.5955 85.4908C56.5929 87.781 61.651 89.1043 66.5347 89.1043C77.6139 89.1043 85.8456 82.7949 88.9738 76.7055Z"
                                                fill="url(#paint2_linear_817_22135)" />
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_817_22135" x1="43.3697" y1="1.90039"
                                                x2="43.3697" y2="71.1362" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.012687" stop-color="#3FC33A" />
                                                <stop offset="0.796446" stop-color="#D48208" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_817_22135" x1="12.5" y1="63.5" x2="65" y2="60"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stop-color="var(--template-color-15)" />
                                                <stop offset="1" stop-color="#45C138" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_817_22135" x1="61.8946" y1="28.7637"
                                                x2="61.8946" y2="89.1043" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.086817" stop-color="#3BC53B" />
                                                <stop offset="0.592871" stop-color="#D38207" />
                                            </linearGradient>
                                            <clipPath id="clip0_817_22135">
                                                <rect width="91" height="91" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="description mt-3">आपका स्वागत है भारत की बुद्धिमत्ता से चलने वाली दुनिया में; इंडिया जीपीटी पर स्वागत है!</p>
                    <p class="description mt-3">इंडिया जीपीटी, ए.आई  संचालित चैटबॉट और मंच के रूप में, लोगों, ब्रांडों और व्यवसायों को सशक्त करने और प्रेरित करने की क्षमता में निहित एक दूरदर्शी मंच बनने की ओर है।</p>
                    <p class="description mt-3">इंडिया जीपीटी, एक ए.आई मंच के रूप में, नवाचारों और ए.आई के केंद्र में है।इंडिया जीपीटी  टीम के रूप में, हम खुश हैं कि ओपन ए.आई की प्रौद्योगिकी की अनंत क्षमताओं और परिवर्तनात्मक क्षमताओं का उपयोग करके भारत में ए.आई मास्टर को लोकतांत्रिक बनाया गया है।</p>

                </div>

            </div>
        </div>
        <div class="container">
            <div class="row ">
                <div class="aboutusVision col-sm-3">

                </div>
                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">हमारे विचार भारत में कृत्रिम बुद्धिमत्ता के भविष्य के लिए:
                            </h3>
                        </div>
                        <p class="description mt-3">इंडिया जीपीटी में हम एक तकनीकी संचालित भविष्य की कल्पना करते हैं जो कृत्रिम बुद्धिमत्ता (ए.आई) की मदद से लोगों का जीवन बेहतर बनाएगा। हमारा लक्ष्य है कि इंटेलिजेंस को हर व्यक्ति के जीवन में एक महत्वपूर्ण और सकारात्मक शक्ति बनाना। इससे हम समाज में अधिक उत्पादकता, नवाचार और समग्र प्रगति का उत्प्रेरक बनेंगे।</p>
                        <p class="description mt-3">हम लोगों, कंपनियों और समुदायों को संचार, समस्या समाधान और रचनात्मकता में सुधार के लिए हमारे उपकरणों का उपयोग करना चाहते हैं।</p>
                        <p class="description mt-3">हम ए.आई के साथ उत्कृष्टता, अधिक जानकारी और सामाजिक प्रभाव बनाने की ओर देख रहे हैं। हम मंच के माध्यम से इन क्षेत्रों और स्थानों में सकारात्मक परिवर्तन लाने का भी लक्ष्य रखते हैं, जिससे अंततः उत्पादकता, कार्यक्षमता और नवाचार में वृद्धि होगी, जिससे भारत की अर्थव्यवस्था बढ़ेगी।</p>
                        <p class="description mt-3">हम एक टीम के रूप में प्रासंगिक और समावेशी ए.आई समाधान प्रदान करने की कल्पना करते हैं क्योंकि हमारा ए.आई संचालित डेटा कई संस्कृतियों को अपना सकता है। हम लोगों, कंपनियों, और उद्योगों की निरंतर बदलती जरूरतों को पूरा करना चाहते हैं, नवीनतम तकनीकी नवाचारों और प्रवृत्तियों के साथ तालमेल बिठाकर।
                            हम एक टीम के रूप में प्रासंगिक और समावेशी ए.आई समाधान प्रदान करने की कल्पना करते हैं क्योंकि हमारा ए.आई संचालित डेटा कई संस्कृतियों को अपना सकता है। हम लोगों, कंपनियों, और उद्योगों की निरंतर बदलती जरूरतों को पूरा करना चाहते हैं, नवीनतम तकनीकी नवाचारों और प्रवृत्तियों के साथ तालमेल बिठाकर।</p>
                        <p class="description mt-3">ताकि व्यक्तिगत और पेशेवर विकास प्राप्त किया जा सके, हर व्यक्ति को इंडिया जीपीटी की मदद से ए.आई का लाभ उठाना सीखना चाहिए। अंत में, हम खुद को भारत में कृत्रिम बुद्धिमत्ता की साक्षरता को बढ़ावा देने के लिए एक उत्प्रेरक के रूप में देखना चाहते हैं। इससे लोगों का कौशल विकास होगा, निरंतर सीखने की संस्कृति विकसित होगी और कृत्रिम बुद्धिमत्ता से संचालित भविष्य बनेगा।</p>
                    </div>
                </div>

            </div>
            <div class="row ">

                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">ए.आई के माध्यम से प्रकाश की मशाल बनना हमारा लक्ष्य है:
                            </h3>
                        </div>
                        <p class="description mt-3">इंडिया जीपीटी के केंद्र में हमारा गहरा मिशन है शीर्ष ए.आई समाधानों, डेटा और जानकारी तक पहुंच को लोकतांत्रिक बनाना, जो लोगों, व्यवसायों, ब्रांडों और उद्योगों की व्यक्तिगत डेटा मांगों के अनुसार बनाए गए हैं। हमारा लक्ष्य था कि इंडिया जीपीटी एक मंच के रूप में हर क्षेत्र में दक्षता, नवाचार और प्रगति को बढ़ा सकता है।</p>
                        <p class="description mt-3">खोज इंजन दुनिया भर में जानकारी देते हैं। हमने इंडिया जीपीटी को प्रस्तुत करने का फैसला किया ताकि उन्हें सब कुछ के लिए एक स्टॉप समाधान मिल सके, न केवल उनकी रोजमर्रा की जानकारी देने के लिए, बल्कि उनके काम, व्यवसाय या जीवन में महत्वपूर्ण ए.आई-संचालित निर्णय लेने में उनकी मदद करने के लिए भी।</p>
                        <p class="description mt-3">भारत एक देश है जो अपनी विविधता और संस्कृतियों के लिए जाना जाता है. इसलिए, हमने इंडिया जीपीटी के साथ आने का निर्णय लिया ताकि लोगों को उनकी आवश्यकताओं के अनुसार ए.आई समाधान मिल सकें। यह मंच सभी के लिए है, चाहे सामग्री निर्माता हो या नहीं |</p>
                    </div>
                </div>
                <div class="aboutusVisiontwo col-sm-3">

                </div>
            </div>
        </div>
        <div class="container">
            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">हमारी ए.आई के साथ उत्कृष्टता प्राप्त करने की प्रतिबद्धता:</h3>
                        </div>
                        <p class="description mt-3">इंडिया जीपीटी टीम में, हम ए.आई के साथ उत्कृष्टता हासिल करने के लिए प्रेरित हैं। यह हमारी प्रतिबद्धता और लक्ष्य है। इंडिया जीपीटी में उच्चतम मानकों की गुणवत्ता और नैतिकता को बनाए रखने के लिए रोजाना काम करने वाले ए.आई विशेषज्ञों और तकनीकी उत्साही लोगों के रूप में हमारी टीम में ऐसे लोग हैं जिन्हें नवाचार और रचनात्मकता में जुनून है।</p>
                        <p class="description mt-3">हम यह भी सुनिश्चित करते हैं कि ए.आई दुनिया के बदलते रुझानों के अनुसार लगातार विकसित होते रहें ताकि उपयोगकर्ताओं को सर्वोत्तम वर्ग और उद्योग-प्रवृत्ति ए.आई समाधान प्रदान किया जा सके ताकि ए.आई क्रांति के अग्रभाग में बने रहें। हम विश्व भर में अन्य लोगों को प्रेरित करते हैं और देश भर में ए.आई मानकों को ऊंचा उठाने के लिए भी प्रतिबद्ध हैं।</p>
                    </div>
                    <div class="px-3 flex-grow-1">
                        <h4 class="sub-headline-decor-small">हमारी विशिष्ट विशेषताएं:</h4>
                        <ul>
                            <li class="description"><b>निष्ठा:</b> जैसा कि पहले कहा गया है, हम विभिन्न उद्योगों में लोगों को विश्वसनीयता, कार्यक्षमता, रचनात्मक ए.आई-संचालित आउटपुट और सर्वोच्च गुणवत्ता वाले ए.आई समाधान देने के लिए प्रतिबद्ध हैं। इसके अलावा, हमारी उपयोगकर्ता-केंद्रित डिजाइन और मंच का उपयोग करते हुए हम उन्हें जो सुविधा देते हैं, वह है।</li>
                            <li class="description"><b>व्यापक डेटा चक्र:</b>हमने विशाल डेटा पर अपने ए.आई मॉडल को प्रशिक्षित किया है, ताकि वे विभिन्न विषयों और क्षेत्रों में महत्वपूर्ण बहस में शामिल हो सकें।</li>
                            <li class="description"><b>उच्चतम स्तर का डिजाइन:</b> टीम ने इसे वास्तविक जीवन में लागू करने के लिए कठोर प्रयास किए हैं, जिससे यह शिक्षकों, व्यवसाय मालिकों, अन्य पेशेवरों और भाषा की विशाल शक्ति को समझने की इच्छा रखने वाले सभी के लिए एक महत्वपूर्ण संपत्ति बन गया है। हमारा उपयोगकर्ता-अनुकूल नेटवर्क भ्रमित नहीं है; इसके बजाय, यह सभी को मंच का एक आसान अनुभव करने के लिए प्रेरित करता है, चाहे वह अनुभवी ए.आई विशेषज्ञ हो या शुरुआती।</li>
                            <li class="description"><b>विशेष समाधान:</b> इंडिया जीपीटी में, हमने सुनिश्चित किया है कि मंच लोगों की आवश्यकताओं और प्रस्तावों के अनुसार है। अनुकूलित कृत्रिम बुद्धिमत्ता समाधान प्रदान करता है अंततः, हम मंच के साथ पेशेवरों और कंपनियों को अपने उद्योगों में सकारात्मक बदलाव लाने में मदद करते हैं |</li>
                        </ul>
                    </div>

                </div>

            </div>

            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">इंडिया जीपीटी  संचार के भविष्य की ओर:</h3>
                        </div>
                        <p class="description mt-3">इंडिया जीपीटी, एक अद्वितीय ए.आई -संचालित मंच, संचार को क्रांतिकारी बनाने के अग्रभाग पर खड़ा है। यह अच्छी तरह से एक विश्वसनीय एआई-संचालित संचार हब बनने की राह पर है। हमने ए.आई की जीपीटी तकनीक की शक्ति और कौशल का सहज एकीकरण सुनिश्चित किया है और एक समावेशी, नवाचार-केंद्रित, क्रांतिकारी मंच बनाने का लक्ष्य रखा है।</p>
                        <p class="description mt-3">इंडिया जीपीटी में हम संदर्भिक रूप से प्रासंगिक बातचीत बनाते हैं, चाहे पेशेवरों की पूछताछ और प्रश्नों में मदद करना हो या शुरुआती या विद्यार्थियों की समस्याओं में मदद करना हो।</p>
                        <p class="description mt-3">हमें पारदर्शी और सुरक्षित संचार प्रदान करने में मदद मिली है जो उपयोगकर्ताओं के मूल्यों के अनुरूप है, क्योंकि हम जिम्मेदार और नैतिक ए.आई के प्रति प्रतिबद्ध हैं। हम निश्चित रूप से कह सकते हैं कि धीरे-धीरे हम तकनीकी रूप से उन्नत संचार के लिए आवश्यक माध्यम बन जाएंगे।</p>
                        <p class="description mt-3">कुल मिलाकर, इंडिया जीपीटी ए.आई की असीम शक्ति का उपयोग करके सकारात्मक बदलाव का एक प्रेरक बनने के लिए प्रतिबद्ध है ताकि एक ऐसा भविष्य बनाया जा सके जहां ए.आई सभी को लाभ पहुंचा सके।</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "AboutUs"
}
</script>

<style>

</style>