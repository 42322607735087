import { createRouter, createWebHistory } from 'vue-router'
import LandingLayout from '@/layouts/LandingLayout.vue'
import CockpitLayout from '@/layouts/CockpitLayout.vue'
import BlankLayout from '@/layouts/BlankLayout.vue'
// landing page
import HomeView from '@/views/landing/HomeView.vue'
// import Search from '@/views/landing/Search.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue'
import TermsAndConditionsScreen from "@/views/landing/TermsAndConditionsScreen.vue"
import PrivacyPolicyScreen from "@/views/landing/PrivacyPolicyScreen.vue"
import AboutUs from "@/views/landing/AboutUs.vue"
// landing page

// cockpit page
import ProfileScreen from '@/views/cockpit/ProfileScreen.vue'
import SettingsScreen from '@/views/cockpit/SettingsScreen.vue'
// cockpit page
// search
import SearchAllComponent from "@/views/cockpit/searchComponents/SearchAll.vue"
import SearchGPTComponent from "@/views/cockpit/searchComponents/SearchGPT.vue"
import SearchWebComponent from "@/views/cockpit/searchComponents/SearchWeb.vue"
import SearchImageComponent from "@/views/cockpit/searchComponents/SearchImage.vue"
import SearchVideoComponent from "@/views/cockpit/searchComponents/SearchVideo.vue"
import SearchNewsComponent from "@/views/cockpit/searchComponents/SearchNews.vue"

// search
// PrivacyPolicyScreen
const routes = [
  // cockpit after login
  {
    path: '/',
    component: LandingLayout,
    redirect: '/',
    children:[
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      
      {
        path: '/terms-and-policy',
        name: 'TermsAndConditionsPage',
        component: TermsAndConditionsScreen,
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicyPage',
        component: PrivacyPolicyScreen,
      },
      {
        path: '/about-us',
        name: 'AboutUsPage',
        component: AboutUs,
      },
    ]
  },
  {
    path: '/',
    component: BlankLayout,
    redirect: '/',
    children:[
      {
        path: '/login',
        name: 'LoginView',
        component: LoginView
      },
      {
        path: '/register',
        name: 'RegisterView',
        component: RegisterView
      },
      {
        path: '/forgot-password',
        name: 'ForgotPasswordView',
        component: ForgotPasswordView
      },
    ]
  },
  

  // cockpit after login
  {
    path: '/cockpit',
    component: CockpitLayout,
    redirect: '/cockpit',
    name: 'DashboardScreen',
    children:[
      {
        path: 'search',
        name: 'search',
        component: SearchAllComponent
      },
      {
        path: 'search/gpt',
        name: 'SearchGPTComponent',
        component: SearchGPTComponent
      },
      {
        path: 'search/web',
        name: 'SearchWebComponent',
        component: SearchWebComponent
      },
      {
        path: 'search/image',
        name: 'SearchImageComponent',
        component: SearchImageComponent
      },
      {
        path: 'search/video',
        name: 'SearchVideoComponent',
        component: SearchVideoComponent
      },
      {
        path: 'search/news',
        name: 'SearchNewsComponent',
        component: SearchNewsComponent
      },
      {
        path: 'profile',
        name: 'ProfileScreen',
        component: ProfileScreen,
      },
      {
        path: 'setting-screen',
        name: 'SettingsScreen',
        component: SettingsScreen,
      },
      {
        path: 'terms-and-policy',
        name: 'TermsAndConditionsScreen',
        component: TermsAndConditionsScreen,
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs,
      },
      { path: '/:pathMatch(.*)*', redirect: '/'  },
    ]
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})
// redirect non www to www

export default router
