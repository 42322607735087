<template>
    <header id="headerNavigation">
        <div class="">
            <div class="d-flex justify-content-between ">
                <div class="p-2 m-0 ms-md-4  brandingLogo">
                    <router-link class="text-decoration-none" :to="{ name:'home' }">
                        <div v-if="$store.getters.getThemeMode == 'light'">
                            <img src="../assets/logo.png" alt="logo" class="rounded" height="50">
                        </div>
                        <div v-else>
                            <img src="../assets/logo-light.png" alt="logo light" class="rounded" height="50">
                        </div>
                    </router-link>
                </div>
                <div class="p-2 w-100  align-self-center d-none d-md-block">
                    
                </div>
                
                <div class="p-2 w-100" v-if="$store.getters.isAuthenticated">
                    <router-link class="text-decoration-none" :to="{ name:'ProfileScreen' }">
                    <div class="d-flex align-items-center justify-content-end avatar_section">
                        
                        <div class="flex-grow-1 mx-3 text-end d-none d-md-inline-block">
                            <h5 class="mb-0">{{ $store.getters.getUser.first_name }}</h5>
                            <p class="mb-0">{{ $store.getters.getUser.email }}</p>
                        </div>
                        <div class="flex-shrink-0">
                            <div v-if="$store.getters.getUserAvatar != ''">
                                <img :src=avatar width="50" height="50" class="object-fit-cover border rounded" alt="user avatar">
                            </div>
                            <div v-else>
                                <img src="../assets/avatar.png" height="50" class="object-fit-cover border rounded" alt="avatar">
                            </div>
                        </div>

                    </div>
                    </router-link>
                </div>
                <div class="p-2 w-100" v-else>
                    <router-link class="text-decoration-none" :to="{ name:'LoginView' }">
                    <div class="d-flex align-items-center justify-content-end avatar_section">
                        
                        <div class="flex-grow-1 mx-3 text-end d-none d-md-inline-block">
                            <h5 class="mb-0">Guest</h5>
                            <p class="mb-0 d-block">Account</p>
                        </div>
                        <div class="flex-shrink-0">
                            <img src="../assets/avatar.png" height="50" class="object-fit-cover border rounded" alt="avatar">
                        </div>
                        
                    </div>
                    </router-link>
                </div>
                <div class="p-2 flex-shrink-1 d-block d-md-none">
                    <button class="btn p-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileAsideBar" aria-controls="mobileAsideBar">
                        <i class="bi bi-list h2"></i>
                    </button>
                </div>
            </div>
        </div>
    </header>
    <section id="CockpitSection">
        <aside id="asideMenu" v-if="$store.getters.isAuthenticated" class="d-none d-md-block">
            <ul>
                <li>
                    <router-link  :to="{ name:'search', query: {q: 'welcome to India' } }">
                        <i class="bi bi-search icon"></i>
                        <span class="d-block">{{ $t('Search') }}</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name:'ProfileScreen' }">
                        <i class="bi bi-person-fill icon"></i>
                        <span class="d-block">{{ $t('menu_profile') }}</span>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link  :to="{ name:'HistoryScreen' }">
                        <i class="bi bi-clock-fill icon"></i>
                        <span class="d-block">{{ $t('menu_history') }}</span>
                    </router-link>
                </li> -->
                
                <!-- <li>
                    <a class="nav-link">
                        <i class="bi bi-question-circle-fill icon"></i>
                        <span class="d-block">Help</span>
                    </a>
                </li> -->
                <li>
                    <router-link  :to="{ name:'SettingsScreen' }">
                        <i class="bi bi-gear-fill icon"></i>
                        <span class="d-block">{{ $t('menu_settings') }}</span>
                    </router-link>
                </li>
            </ul>
            <ul class="d-none d-md-block bottomMenu">
                <li class="mb-0">
                    <a @click="logout()">
                        <i class="bi bi-box-arrow-left icon"></i>
                        <span class="d-block">{{ $t('menu_logout') }}</span>
                    </a>
                </li>
            </ul>
        </aside>
        <div id="project_container" class="w-100">
            <!-- <router-view/> -->
            <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component" :key="$route.fullPath"></component>
            </keep-alive>
            </router-view>
        </div>
    </section>    
    
    

    <div class="offcanvas offcanvas-start" tabindex="-1" id="mobileAsideBar" aria-labelledby="mobileAsideBarLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="mobileAsideBarLabel">
                <router-link class="text-decoration-none" :to="{ name:'home' }">
                    <div v-if="$store.getters.getThemeMode == 'light'">
                        <img src="../assets/logo.png" alt="logo" class="rounded" height="50">
                    </div>
                    <div v-else>
                        <img src="../assets/logo-light.png" alt="logo light" class="rounded" height="50">
                    </div>
                </router-link>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="list-group list-group-flush m-0" v-if="$store.getters.isAuthenticated">
                <li class="list-group-item">
                    <router-link  :to="{ name:'home' }" class="text-decoration-none text-secondary">
                        <span class="d-inline-block">{{ $t('menu_home') }}</span>
                    </router-link>
                </li>
                <li class="list-group-item">
                    <router-link  :to="{ name:'ProfileScreen' }" class="text-decoration-none text-secondary">
                        <span class="d-inline-block">{{ $t('menu_profile') }}</span>
                    </router-link>
                </li>
                <li class="list-group-item">
                    <router-link  :to="{ name:'SettingsScreen' }" class="text-decoration-none text-secondary">
                        <span class="d-inline-block">{{ $t('menu_settings') }}</span>
                    </router-link>
                </li>
            </ul>
            <ul class="list-group list-group-flush m-0" v-else>
                <li class="list-group-item">
                    <router-link  :to="{ name:'home' }" class="text-decoration-none text-secondary">
                        <span class="d-inline-block">{{ $t('menu_home') }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="offcanvas-footer">
            <div class="card">
                <div class="card-body d-flex  align-items-center">
                    <div class="p-2 w-100" v-if="$store.getters.isAuthenticated">
                        <router-link class="text-decoration-none" :to="{ name:'ProfileScreen' }">
                        <div class="d-flex align-items-center justify-content-between avatar_section">
                            
                            <div class="flex-grow-1 mx-3 text-end d-none d-md-inline-block">
                                <h5 class="mb-0">{{ $store.getters.getUser.first_name }}</h5>
                                <p class="mb-0">{{ $store.getters.getUser.email }}</p>
                            </div>
                            <div class="flex-shrink-0">
                                <div v-if="$store.getters.getUserAvatar != ''">
                                    <img :src=avatar width="50" height="50" class="object-fit-cover border rounded" alt="user avatar">
                                </div>
                                <div v-else>
                                    <img src="../assets/avatar.png" height="50" class="object-fit-cover border rounded" alt="avatar">
                                </div>
                            </div>
                        </div>
                        </router-link>
                    </div>
                    <div class="p-2" v-else>
                        <router-link class="text-decoration-none" :to="{ name:'LoginView' }">
                        <div class="d-flex align-items-center avatar_section">
                            
                            <div class="flex-shrink-0">
                                <img src="../assets/avatar.png" height="50" class="object-fit-cover border rounded" alt="avatar">
                            </div>
                            <div class="flex-grow-1 mx-3 text-start ">
                                <h5 class="mb-0">Guest</h5>
                                <p class="mb-0 d-block">Account</p>
                            </div>
                            
                            
                        </div>
                        </router-link>
                    </div>
                    <div v-if="$store.getters.isAuthenticated">
                        <a @click="logout()" class="button button_sm text-center d-flex">
                            <i class="bi bi-box-arrow-left icon"></i>
                            <span class="d-block">{{ $t('menu_logout') }}</span>
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
      return {
        conversation_data : [],
        loading_conversation:false,
        avatar: this.$store.getters.getUserAvatar
      }
    },
    // mounted(){
    //     this.checkLogin()
    // },
    // created()
    // {
    //     this.checkLogin()
    // },
    methods: {
        createNewConversation()
        {
            this.$store.commit('SET_CONVERSATION_ID', "")
            this.$router.push({ name: 'DashboardScreen' })
            window.location.href = "/cockpit";
        },
        checkLogin()
        {
            if(this.$store.getters.isAuthenticated == false)
            {
                this.$router.push({ name: 'LoginView' })
            }
        },
        logout() {
            this.$router.push({ name: 'LoginView' })
            this.$store.dispatch('logout')
        },
    },
};
</script>

<style>

#headerNavigation
{
    height: 90px;
    padding: 10px 0;
    z-index: 10;
    position: relative;
}
[data-bs-theme="dark"] #headerNavigation
{
    background: var(--template-color-8);
}

#headerNavigation .nav-link
{
    color: var(--template-color-9);
    text-align: center;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}

/* avatar_section */
.avatar_section img 
{
    border-radius: 50% !important;
}
.avatar_section h5
{
    color: var(--template-color-9);
}
.avatar_section p
{
    color: var(--template-color-28);
}
[data-bs-theme="dark"] .avatar_section h5
{
    color: var(--template-color-7);
}
/* avatar_section */

#CockpitSection 
{
    height: calc(100vh - 90px);
    max-height: calc(100dvh - 90px) !important;
    background: var(--template-color-29);
    display: flex;    
}

[data-bs-theme="dark"] #CockpitSection
{
    background: var(--template-color-2);
}
#project_container
{
    width: calc(100% - 120px);
    overflow-y: auto;
    overflow-x: hidden;
}
.brandingLogo
{
    width: 120px;
    display: block;
    text-align: center;
}
#asideMenu
{
    width: 120px !important;
    padding: 30px 10px !important;
    text-align: center;
    height: 100%;
    background: var(--template-color-0);
    display: flex;
    flex-direction:column;
    justify-content:space-between;
    overflow-y: auto;
}
[data-bs-theme="dark"] #asideMenu
{
    background: var(--template-color-8);
    color:  var(--template-color-0);
}
#asideMenu ul
{
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}

#asideMenu ul li
{
    display: block;
    margin-bottom: 32px;
}

#asideMenu ul li a{
    display: block;
    text-decoration: none;
    position: relative;
    padding-top: 10px;
}


#asideMenu ul li a .icon
{
    font-size: 20px;
    color: var(--template-color-32);
    height: 50px;
    width: 50px;
    line-height: 45px;
    display: block;
    margin: auto;
    background:  var(--template-color-0);
    border: var(--template-color-30) 1px solid;
    border-radius: 50%;
}
#asideMenu ul li a span
{
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 375% */
    color: var(--template-color-18);
}

[data-bs-theme="dark"] #asideMenu ul li a .icon
{
    color: var(--template-color-18);
    background: var(--template-color-8);
    border: var(--template-color-30) 1px solid;
}

#asideMenu ul li a.router-link-exact-active .icon
{
    color: var(--template-color-11);    
}
#asideMenu ul li a.router-link-exact-active span
{
    color: var(--template-color-11);    
}
#asideMenu ul li a.router-link-exact-active::before
{
    content: "";
    height: 100%;
    width: 10px;
    border-radius: 0 10px 10px 0;
    background: var(--template-color-11);
    position: absolute;
    left: -10px;
    top: 0;
}

[data-bs-theme="dark"] #asideMenu ul li a.router-link-exact-active .icon
{
    color: var(--template-color-20);    
}
[data-bs-theme="dark"] #asideMenu ul li a.router-link-exact-active span
{
    color: var(--template-color-20);    
}
[data-bs-theme="dark"] #asideMenu ul li a.router-link-exact-active::before
{
    background: var(--template-color-);
}
/* height: calc(100% - 100px); */



/* buttons */
.theme_button
{
    display: inline-flex;
    height: 49px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--template-color-11);
    color:  var(--template-color-0);
    border: 1px solid var(--template-color-11);
}

[data-bs-theme="dark"] .theme_button
{
    background: var(--template-color-20);
    color:  var(--template-color-0);
    border: 1px solid var(--template-color-11);
}
.theme_button.outline
{
    border-radius: 10px;
    border: 1px solid var(--template-color-14);
    background:  var(--template-color-0);
    color: var(--template-color-11);
}
.theme_button:hover
{
    background: var(--template-color-0) !important;
    color: var(--template-color-11) !important;
    border: solid var(--template-color-11) 1px;
}
[data-bs-theme="dark"] .theme_button:hover
{
    color: var(--template-color-20) !important;
    border: solid var(--template-color-20) 1px;
}
/* buttons */

@media screen and (max-width: 770px) {
    #CockpitSection 
    {
        height: calc(100vh - 90px) !important;
        background:var(--template-color-29);
        display: flex !important; 
        flex-direction:column-reverse;   
    }
    #project_container
    {
        width: 100%;
        height: 100% !important;
        overflow-y: auto;
        overflow-x: hidden;
    }
    #asideMenu
    {
        width: 100% !important;
        width: 100% !important;
        padding: 10px !important;
        height: auto;
        bottom: 0;
        overflow: hidden;
    }
    #asideMenu ul
    {
        display: flex !important;
        flex-direction:row !important;
        justify-content:space-around !important;
        align-items: center;
        justify-content: center;
        width: 100% !important;
    }
    #asideMenu ul li 
    {
        margin-bottom: 0;
    }
    #asideMenu ul li a.router-link-exact-active::before
    {
        content: "";
        height: 5px;
        width: 100%;
        border-radius: 0 0 10px 10px;
        background: var(--template-color-11);
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: auto;
        top: -10px;
    }
    #asideMenu ul.bottomMenu
    {
        display: none !important;
    }
    #asideMenu .nav-link .icon
    {
        font-size: 15px;
        color: var(--template-color-32);
        height: 40px;
        width: 40px;
        line-height: 38px;
        display: block;
        margin: auto;
        background: var(--template-color-0);
        border: var(--template-color-30) 1px solid;
        border-radius: 50%;
    }
    #CockpitSection 
    {
        height: calc(100vh - 200px);
        background:rgba(205, 211, 237, 0.5)
    }
    .theme_button
    {
        display: inline-flex;
        height: auto;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--template-color-11);
        color:  var(--template-color-0);
        font-size: 12px;
    }
}

dl, ol, ul {
    margin: 0;
    padding: 0;
}
</style>