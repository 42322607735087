<template>
    <div class="card bg-transparent border-0" >
        
        <div class="card-body bg-transparent">
            <div class="card p-3 mb-4">
                <div class="card-header bg-transparent border-0">
                    <h1 class="h5 mb-0">{{$t('headline_my_profile')}}</h1>
                    <small>{{$t('sub_headline_my_profile_desc')}}</small>
                </div>
                <div class="card-body border-1">
                    <div class="card card-body">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-md">
                                <div class="d-flex align-items-center avatar_section">
                                    <div class="flex-shrink-0">
                                        <div v-if="user_avatar == ''">
                                            <img src="../../assets/avatar.png" alt="avatar" class="rounded" height="50" >
                                        </div>
                                        <div v-else-if="user_avatar == null">
                                            <img src="../../assets/avatar.png" class="rounded" height="50" alt="avatar">
                                        </div>
                                        <div v-else>
                                            <img :src=user_avatar width="50" height="50" class="object-fit-cover border rounded" alt="user avatar">
                                        </div>                                        
                                    </div>
                                    <div class="flex-grow-1 mx-3">
                                        <h5 class="mb-0">{{ profile_data.first_name }} {{ profile_data.last_name }}</h5>
                                        <p class="mb-0">{{ profile_data.email }}</p>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-md">
                                <div class="d-flex gap-3 flex-row-reverse mt-3 mt-md-0" role="group" aria-label="Basic example">
                                    <!-- <button type="button" class="theme_button outline">{{$t('button_remove')}}</button> -->
                                    <button type="button" class="theme_button" @click="toggleImageModal">{{$t('button_update')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <form class="row g-3">
                                <div class="col-md-6">
                                    <label for="input1" class="form-label">{{$t('lebel_first_name')}}</label>
                                    <input type="text" class="form-control" id="input1" v-model="profile_data.first_name">
                                    <span class="text-danger w-100 text-end" v-if="profile_data.error && v$.profile_data.first_name.$error"> {{ v$.profile_data.first_name.$errors[0].$message }} </span>
                                </div>
                                <div class="col-md-6">
                                    <label for="input2" class="form-label">{{$t('lebel_last_name')}}</label>
                                    <input type="text" class="form-control" id="input2" v-model="profile_data.last_name">
                                    <span class="text-danger w-100 text-end" v-if="profile_data.error && v$.profile_data.last_name.$error"> {{ v$.profile_data.last_name.$errors[0].$message }} </span>
                                </div>
                                <div class="col-md-6">
                                    <label for="input3" class="form-label">{{$t('lebel_email_address')}}</label>
                                    <input type="email" class="form-control" id="input3" v-model="profile_data.email">
                                    <span class="text-danger w-100 text-end" v-if="profile_data.error && v$.profile_data.email.$error"> {{ v$.profile_data.email.$errors[0].$message }} </span>
                                </div>
                                <div class="col-md-6">
                                    <label for="input4" class="form-label">{{$t('lebel_phone_number')}}</label>
                                    <input type="tel" class="form-control" id="input4" v-model="profile_data.mobile">
                                    <span class="text-danger w-100 text-end" v-if="profile_data.error && v$.profile_data.mobile.$error"> {{ v$.profile_data.mobile.$errors[0].$message }} </span>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
                <div class="card-footer bg-transparent border-0">
                    <div class="d-flex gap-3 flex-row-reverse" role="group" aria-label="Basic example" v-if="profile_data.loading == false">
                        <!-- <button type="button" class="theme_button outline">Discard</button> -->
                        <button type="button" class="theme_button" @click="updateProfile()">{{$t('button_save')}}</button>
                    </div>
                    <div class="d-flex gap-3 flex-row-reverse" role="group" aria-label="Basic example" v-else>
                        <!-- <button type="button" class="theme_button outline">Discard</button> -->
                        <button type="button" class="theme_button disabled">Please wait ...</button>
                    </div>
                </div>
            </div>
            
            <div class="card p-3">
                <div class="card-header bg-transparent border-0">
                    <h1 class="h5 mb-0">{{$t('headline_change_password')}}</h1>
                    <small>{{$t('sub_headline_change_password_desc')}}</small>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <form class="row g-3">
                                <div class="col-md-6">
                                    <label for="inputEmail4" class="form-label">{{$t('lebel_change_password')}}</label>
                                    <input type="password" class="form-control" id="inputEmail4" v-model="password_data.current_password">
                                    <span class="text-danger w-100 text-end" v-if="password_data.error && v$.password_data.current_password.$error"> {{ v$.password_data.current_password.$errors[0].$message }} </span>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputPassword4" class="form-label">{{$t('lebel_new_password')}}</label>
                                    <input type="password" class="form-control" id="inputPassword4" v-model="password_data.new_password">
                                    <span class="text-danger w-100 text-end" v-if="password_data.error && v$.password_data.new_password.$error"> {{ v$.password_data.new_password.$errors[0].$message }} </span>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    
                </div>
                <div class="card-footer bg-transparent border-0">
                    
                    <div class="d-flex gap-3 flex-row-reverse" role="group" aria-label="Basic example" v-if="password_data.loading == false">
                        <!-- <button type="button" class="theme_button outline">Discard</button> -->
                        <button type="button" class="theme_button" @click="changePasssword()">{{$t('button_save')}}</button>
                    </div>
                    <div class="d-flex gap-3 flex-row-reverse" role="group" aria-label="Basic example" v-else>
                        <!-- <button type="button" class="theme_button outline">Discard</button> -->
                        <button type="button" class="theme_button disabled">Please wait ...</button>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <!-- profile picture upload -->
    
    <div v-if="profile_picture.modal">
        <div class="pro_modal show">
            <div class="pro_modal_dialog">
                <div class="pro_modal_content">
                <div class="pro_modal_header">
                    <h5 class="pro_modal_title">Update profile picture</h5>
                    <button type="button" class="btn-close" @click="toggleImageModal"></button>
                </div>
                <div class="pro_modal_body">
                    <div>
                        <input class="form-control" type="file" ref="fileInput" accept="image/png, image/gif, image/jpeg" @change="handleFileChange" />
                        <!-- <button @click="uploadImage()">Upload</button> -->
                        <div v-if="profile_picture.uploadProgress !== null" class="text-center w-100 p-3">
                            Uploading... {{ profile_picture.uploadProgress }}%
                        </div>
                    </div>
                </div>
                
                <div class="pro_modal_footer">
                    <button type="button" class="theme_button outline" @click="toggleImageModal">Cancel</button>
                    
                    <div v-if="profile_picture.loading == false">
                        <button type="button" class="theme_button" @click="uploadImage()">
                            Upload Image
                        </button>
                    </div>
                    <div v-else>
                        <button type="button" class="theme_button bg-danger" disabled>
                            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            Uploading ...
                        </button>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
    </div>
    <!-- profile picture upload -->
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength, email, maxLength } from '@vuelidate/validators';
import axios from 'axios';
import { useHead } from "@vueuse/head";
import { ref } from 'vue';

export default{
    name:"ProfileScreen",
    data(){
        return {
            user_avatar: ((this.$store.getters.getUserAvatar == null) ? null : this.$store.getters.getUserAvatar),
            profile_data : {
                first_name : this.$store.getters.getUser.first_name,
                last_name : this.$store.getters.getUser.last_name,
                email : this.$store.getters.getUser.email,
                mobile : this.$store.getters.getUser.mobile,
                error : false,
                loading: false
            },
            password_data : {
                current_password : "",
                new_password : "",
                error : false,
                loading: false
            },
            profile_picture : {
                modal : false,
                selectedFile: null,
                uploadProgress: null,
                error : false,
                loading: false
            }
        }
    },

    setup()
    {
        const pageTitle = ref('My Profile - India GPT');

        useHead({
            title: pageTitle
        });

        // Function to change the title
        function updateTitle(newTitle) {
            pageTitle.value = newTitle;
        }

        return {
            updateTitle,
            v$: useVuelidate(),
        };
    },
    validations() {
        return {
            profile_data : {
                first_name: {
                    required
                },
                last_name: {
                    required
                },
                email: {
                    required, email
                },
                mobile: { 
                    required,
                    minLength: minLength(9),maxLength: maxLength(15),
                },
            },
            password_data : {
                current_password: {
                    required,
                },
                new_password: { 
                    required,
                    minLength: minLength(8),
                },
            }
        }
    },
    methods:
    {
        updateProfile()
        {
            this.v$.profile_data.$touch();
            if (!this.v$.profile_data.$invalid) {
                this.profile_data.loading = true
                // calling api
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/update-profile/`;
                const formData = axios.toFormData(this.profile_data)
                const headers = { 
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.post(API_URL, formData, {headers})
                .then((response) => {
                    if(response.status == 203)
                    {
                        this.$toast.error(response.data.message, {
                            icon: "dangerous",
                        }); 
                    }
                    else if(response.status == 200)
                    {
                        this.$store.commit('SET_USER', this.profile_data)
                        this.$toast.success(response.data.message, {
                            icon: "dangerous",
                        }); 
                    }
                    console.log(response.data);
                    this.profile_data.loading = false;

                })
                .catch((error) => {
                // Handle errors
                    console.log(error);
                    this.$toast.error("Ops something is wrong. try again later", {
                        icon: "dangerous",
                    }); 
                    this.profile_data.loading = false;
                }); 
                // calling api
            } else {
                console.log("not done");
                this.$toast.error("Please fill the required fields", {
                    icon: "dangerous",
                });        
                this.profile_data.error = true;
                this.profile_data.loading = false;
            } 
        },
        changePasssword()
        {
            this.v$.password_data.$touch();
            if (!this.v$.password_data.$invalid) {
                this.password_data.loading = true
                // calling api
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/change-password/`;
                const formData = axios.toFormData(this.password_data)
                const headers = { 
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.post(API_URL, formData, {headers})
                .then((response) => {
                    if(response.status == 203)
                    {
                        this.$toast.error(response.data.message, {
                            icon: "dangerous",
                        }); 
                    }
                    else if(response.status == 200)
                    {
                        this.$toast.success(response.data.message, {
                            icon: "dangerous",
                        }); 

                        this.password_data.current_password = ""
                        this.password_data.new_password = ""
                    }
                    console.log(response.data);
                    this.password_data.loading = false
                })
                .catch((error) => {
                // Handle errors
                    console.log(error);
                    this.$toast.error("Ops something is wrong. try again later", {
                        icon: "dangerous",
                    }); 
                    this.password_data.loading = false
                }); 
                // calling api
            }
            else {
                console.log("not done");
                this.$toast.error("Please fill the required fields", {
                    icon: "dangerous",
                });        
                this.password_data.error = true;
                this.password_data.loading = false;
            } 
        },
        // change-password

        // upload image
        handleFileChange(event) {
            this.profile_picture.selectedFile = event.target.files[0];
            console.log(this.profile_picture.selectedFile)
        },
        
        toggleImageModal()
        {
            this.profile_picture.modal = !this.profile_picture.modal
        },

        async uploadImage()
        {
            if (this.profile_picture.selectedFile == null) {
                return;
            }

            this.profile_picture.loading = true;

            const formData = new FormData();
            formData.append('file', this.profile_picture.selectedFile);

            const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/upload-profile-picture/`;
            // const formData = axios.toFormData(this.profile_data)
            const headers = { 
                "Authorization": `Bearer ${this.$store.getters.getToken}`,
            };

            try {
                const response = await axios.post(API_URL, formData, {headers},{
                    onUploadProgress: (progressEvent) => {
                        console.log(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                        this.profile_picture.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    },
                });
                // Handle the response as needed
                console.log('Upload successful:', response.data);
                this.$store.commit('SET_USER_AVATAR', response.data.data.url)
                this.user_avatar = response.data.data.url
                this.profile_picture.modal = false;
                window.location.reload();
                this.profile_picture.loading = false;

            } catch (error) {
                console.error('Error uploading image:', error);
                this.profile_picture.loading = false;

            } finally {
                // Reset the form and progress bar
                this.profile_picture.selectedFile = null;
                this.profile_picture.uploadProgress = null;
                this.$refs.fileInput.value = ''; // Clear the file input
                this.profile_picture.loading = false;

            }
        },
        // upload image
    }
}
</script>