<template>
    <section class="homeSearch">
        <div class="container text-center">
            <div class="row align-items-center justify-content-center vh-100" >
                <div class="col-md-12">
                    <img src="@/assets/hero-image.webp" class="w-100 h-100 rounded" alt="Hero Image">
                    <div class="card card-body p-0 border-0 bg-transparent my-3">
                        <form class="d-flex gap-2 py-sm-5" method="post" action="search" id="searchBox"
                            @submit.prevent="searching()">
                            <div class="d-flex w-100 bg-transparent rounded">
                                <input type="search" autocomplete="off" @keyup="getKeywordSuggestions()" autofocus required
                                    name="q" list="suggetions" class="searchInput form-control form-control-lg border-0"
                                    :disabled="loading" :class="{ active: question_form.question, 'text-danger': hasError }"
                                    :placeholder="$t('placeholder_serach')" aria-label="With textarea"
                                    v-model="question_form.question">
                                <datalist id="suggetions">
                                    <option :value="suggestion" v-for="suggestion in search_keyword_suggesion"
                                        :key="suggestion">{{ suggestion }}</option>
                                </datalist>
                            </div>
                            <div class="d-flex flex-row justify-content-center gap-2">
                                <button type="button" v-if="isMicAvailable"
                                    class="btn btn-outline-default waves searchButton" :class="{ active: isListening }"
                                    @click="toggleRecognition" :title="isListening ? 'Stop Listening' : 'Start Listening'">
                                    <i class="bi bi-mic-fill"></i>
                                </button>
                                <button class="btn btn-outline-default searchButton" type="submit" aria-label="Search">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>

                    <!-- <p class="m-1 small">{{$t('message_below_search')}}</p> -->
                    <small class="py-sm-4">{{ $t('message_below_search') }}</small>
                    <p class="my-3">
                        {{ $t('India GPT offered in') }} <a style="font-size: 16px;" href="#"
                            class="fw-bold text-secondary text-link" @click="changeLanguage('en')">English</a> <a
                            style="font-size: 16px;" href="#" class="text-secondary fw-bold text-link"
                            @click="changeLanguage('hi')">{{ $t('India GPT offered in hindi') }}</a>
                    </p>

                    <DownloadPWA class="my-5"></DownloadPWA>

                    <div class="loading" v-if="loading">
                        <img src="../assets/splash.gif" height="50" alt="splash">
                    </div>

                    <div class="field">
                        <a class="scroll" href="#home"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style></style>
<script>
import axios from 'axios';
import DownloadPWA from "@/components/DownloadPWA.vue";

// import AutoComplete from 'primevue/autocomplete';

export default {
    name: "DashboardScreen",
    data() {
        return {
            conversation_id: this.$store.getters.conversationId,
            conversation_data: [],
            google_image_response: [],
            search_keyword_suggesion: [],
            question_form: {
                question: ""
            },
            loading: false,

            isListening: false,
            recognition: null,
            isMicAvailable: true
        }
    },

    methods: {
        changeLanguage(language) {
            this.$store.commit('SET_LANGUAGE', language)
            location.reload();
        },
        toggleRecognition() {
            this.checkMicAvailability()
            if (this.isMicAvailable) {
                if (this.isListening) {
                    this.recognition.stop();
                }
                else {
                    this.recognition.start();
                }
            }
            else {
                this.$toast.error("Mic is not available", {
                    icon: "dangerous",
                });
            }
        },
        checkForCommand(result) {
            const t = result.transcript;
            if (t.includes('stop')) {
                this.recognition.stop();
            }
        },
        checkMicAvailability() {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    // Code for success
                    console.log(stream)
                    this.isMicAvailable = true
                    this.checkMicAvailability()
                }).catch(err => {
                    console.log(err)
                    this.isMicAvailable = false
                })
        },

        searching() {
            if (this.question_form.question != "") {
                this.$router.push({ name: 'search', query: { q: this.question_form.question } })
            }
            else {
                this.$toast.error("Questions are required");
            }
        },
        getKeywordSuggestions() {
            if (this.question_form.question != "") {
                const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/search/suggestions/${this.question_form.question}/`;
                const headers = {
                    "Authorization": `Bearer ${this.$store.getters.getToken}`,
                };

                axios.get(API_URL, { headers })
                    .then((response) => {
                        if (response.status == 204) {
                            // this.$toast.error(response.data.message, {
                            //     icon: "dangerous",
                            // }); 
                        }
                        else if (response.status == 200) {
                            this.search_keyword_suggesion = response.data.suggestion
                        }

                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                        this.$toast.error("Ops something is wrong. try again later", {
                            icon: "dangerous",
                        });
                        this.search_engine_loading = false;
                    });
            }
        }
    },

    mounted() {
        // to use mic
        this.recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onstart = () => {
            console.log('Recognition Started');
            this.isListening = true;
        };

        this.recognition.onend = () => {
            console.log('Recognition Stopped');
            this.isListening = false;
        };

        this.recognition.onresult = (evt) => {
            let finalTranscript = '';
            for (let i = 0; i < evt.results.length; i++) {
                const result = evt.results[i];
                if (result.isFinal) {
                    finalTranscript += result[0].transcript + ' ';
                    this.checkForCommand(result[0]);
                }
            }
            this.question_form.question = finalTranscript.trim();
        };
        // to use mic
    },

    beforeMount() {
        if (this.$route.query.conversation == "new") {
            this.conversation_data = []
            this.conversation_id = ""
        }
    },

    components: {
        // AutoComplete,
        DownloadPWA
    }
}
</script>