<template>
    <!-- about -->
    <section id="aboutuspaage" ref="section2" class="py-5">
        <div class="container py-1">
            <div class="row align-items-center justify-content-center ">

                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <h3 class="sub-headline-decor-small">IndiaGPT – About Us</h3>
                                </div>
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 91 91"
                                        fill="none">
                                        <g clip-path="url(#clip0_817_22135)">
                                            <path
                                                d="M30.9126 14.0716C34.3213 9.76431 40.7406 7.63718 45.1162 7.52723C57.386 7.29214 62.501 22.7432 62.8953 28.8857L68.5753 30.6071C67.2671 11.7777 55.6836 1.90039 44.6005 1.90039C34.2076 1.8966 24.6109 9.53302 20.7434 20.7298C15.4616 35.6576 18.9272 50.0091 25.4033 61.494C24.8384 62.241 24.4971 63.5567 24.6109 64.8193C24.7208 68.2659 27.6177 71.1362 31.087 71.1362H31.3145C32.173 71.1116 33.0178 70.9156 33.7993 70.5598C34.5809 70.2039 35.2834 69.6954 35.8656 69.0641C36.4478 68.4328 36.8979 67.6915 37.1894 66.8838C37.481 66.076 37.608 65.2181 37.5632 64.3605C37.5632 60.9139 34.6095 58.0512 31.1439 58.0512H30.9126C30.6851 58.0512 30.3477 58.0512 30.0595 58.1611C25.3465 50.1228 23.3558 41.5081 24.099 32.2148C24.5502 25.2078 26.8252 19.1221 30.9126 14.0716Z"
                                                fill="url(#paint0_linear_817_22135)" />
                                            <path
                                                d="M3.53245 79.2372C7.33929 84.9778 15.1236 88.6481 23.6435 88.6481C25.9147 88.6481 28.3034 88.4813 30.6315 87.905C45.4038 85.0347 56.5968 76.0788 63.0161 62.8762L63.0692 62.7586C66.3642 62.4705 68.9197 59.5395 68.7529 56.036C68.5785 52.5325 65.7385 49.7229 62.2767 49.7229H62.0455C58.4699 49.8404 55.683 52.8814 55.7968 56.5024C55.9105 58.3376 56.6537 59.8315 57.6699 60.9197C53.6962 68.7305 47.7319 74.4673 38.6964 79.2941C32.6183 82.5056 26.199 83.7152 19.9504 82.8545C14.7255 82.1113 10.638 79.7491 8.13554 75.965C4.38937 70.2206 4.04812 64.0251 7.16866 57.8257C9.44745 53.3477 12.8524 50.0755 15.1274 48.4641C14.5586 46.9739 13.8761 44.4487 13.5349 42.5566C-3.28875 54.7241 -1.57871 71.3695 3.53245 79.2372Z"
                                                fill="url(#paint1_linear_817_22135)" />
                                            <path
                                                d="M88.9738 76.7055C92.3787 69.816 92.0981 58.2211 83.4038 48.287L83.3507 48.4007C74.5503 37.9509 61.5941 32.2103 46.818 32.2103H44.8843C43.925 30.087 41.7106 28.7637 39.2081 28.7637H39.0375C35.462 28.7637 32.6751 31.869 32.7926 35.4825C32.9064 38.9253 35.8032 41.7994 39.2688 41.7994H39.5494C40.7824 41.7468 41.9741 41.3396 42.9816 40.6267C43.989 39.9139 44.7695 38.9255 45.2293 37.7803H47.3337C56.0886 37.7803 64.3772 40.3624 71.9378 45.4129C77.7277 49.2614 81.8796 54.3119 84.2076 60.3407C86.2476 65.2774 86.1376 70.1004 84.037 74.1234C80.7989 80.4365 75.3427 83.7694 68.131 83.7694C63.5848 83.7694 59.1561 82.3323 56.8811 81.301C55.5199 82.4423 53.2449 84.3381 51.5955 85.4908C56.5929 87.781 61.651 89.1043 66.5347 89.1043C77.6139 89.1043 85.8456 82.7949 88.9738 76.7055Z"
                                                fill="url(#paint2_linear_817_22135)" />
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_817_22135" x1="43.3697" y1="1.90039"
                                                x2="43.3697" y2="71.1362" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.012687" stop-color="#3FC33A" />
                                                <stop offset="0.796446" stop-color="#D48208" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_817_22135" x1="12.5" y1="63.5" x2="65" y2="60"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stop-color="var(--template-color-15)" />
                                                <stop offset="1" stop-color="#45C138" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_817_22135" x1="61.8946" y1="28.7637"
                                                x2="61.8946" y2="89.1043" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.086817" stop-color="#3BC53B" />
                                                <stop offset="0.592871" stop-color="#D38207" />
                                            </linearGradient>
                                            <clipPath id="clip0_817_22135">
                                                <rect width="91" height="91" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="description mt-3">Welcome to a world powered by the mastery of Artificial Intelligence (AI);
                        welcome to IndiaGPT!</p>
                    <p class="description mt-3">IndiaGPT, as a one-of-a-kind AI-powered platform and chatbot, is well on its
                        way to becoming a visionary platform that has ingrained in it the power to empower and inspire
                        people, brands, and businesses.</p>
                    <p class="description mt-3">It can rightfully be said that IndiaGPT, as an AI platform, is at the
                        intersection of AI and innovations. As a team at IndiaGPT, we are passionate about how well we have
                        immersed ourselves in democratizing AI mastery to the people of Bharat as a nation by harnessing the
                        unending power and transformative capabilities of Open AI's GPT technology.</p>
                </div>

            </div>
        </div>
        <div class="container">
            <div class="row ">
                <div class="aboutusVision col-sm-3">

                </div>
                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Our Vision for Pioneering the Future of AI in Bharat, India
                            </h3>
                        </div>
                        <p class="description mt-3">At IndiaGPT, we foresee a tech-driven future that will brighten people’s
                            lives through the power of Artificial Intelligence. We aim to make AI a vital aspect and a
                            positive force in every individual’s life. This will lead us toward becoming positive catalysts
                            of change, creating more productivity, innovations, and overall progress for society</p>
                        <p class="description mt-3">We envision empowering people, businesses, and communities with our
                            tools to enhance communication, problem-solving, and creativity.</p>
                        <p class="description mt-3">With a multi-faceted vision in mind, we look forward to creating
                            excellence with AI, more accessibility to information, and societal impact. Through the
                            platform, we also aim to provide people with industry-specific AI solutions for creating
                            positive transformations across these sectors and niches to eventually enhance productivity,
                            efficiency, and innovation, growing the Indian economy.</p>
                        <p class="description mt-3">Our AI-powered data knows how to embrace varied cultures, and thus, as a
                            team, we envision providing AI solutions that are relevant and inclusive. We wish to cater to
                            the ever-changing needs of people, businesses, and industries by keeping pace with the latest
                            tech trends and innovations.</p>
                        <p class="description mt-3">By harnessing the power of AI, every individual from across industries
                            and areas of expertise must learn how to leverage AI’s power with the help of IndiaGPT to gain
                            personal and professional growth. Ultimately, we want to see ourselves as a catalyst for
                            promoting AI literacy across Bharat, India. This will also lead to skills development among
                            people, fostering a culture of continuous learning and creating an AI-driven future.</p>
                    </div>
                </div>

            </div>
            <div class="row ">

                <div class="col-sm-9">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Our Mission to Act as a Beacon of Light through AI
                            </h3>
                        </div>
                        <p class="description mt-3">At the core of IndiaGPT lies our profound mission to democratize access
                            to top-notch AI solutions, data, and information tailored to the individual data needs and
                            demands of people, businesses, brands, and industries. We started our journey keeping in mind
                            that IndiaGPT as a platform can become a positive force for driving efficiency, innovation, and
                            progress in every sector.</p>
                        <p class="description mt-3">Around the world, people turn to search engines to find the information
                            they seek. We decided to introduce IndiaGPT to offer them a one-stop solution for everything,
                            not just to provide them with the everyday information they need but also with AI-powered
                            solutions to make critical AI-powered decisions in their work/business or life.</p>
                        <p class="description mt-3">As a nation, Bharat is known for its rich diversity and cultures, and to
                            resonate with this diversity, we came up with IndiaGPT to offer people AI solutions that can be
                            tailored to their requirements. This platform is for anyone and everyone, be it content
                            creators, business owners, students, leaders, top voices across sectors, artists, musicians,
                            professionals across fields, and many more.</p>
                    </div>
                </div>
                <div class="aboutusVisiontwo col-sm-3">

                </div>
            </div>
        </div>
        <div class="container">
            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">Our Commitment to Achieving Excellence with AI</h3>
                        </div>
                        <p class="description mt-3">As a team, we at IndiaGPT are driven to achieve our goals of attaining
                            excellence with AI. For us, this is not just our goal but our commitment as well. Our team
                            consists of individuals who find passion in innovations and creativity and, hence, have been
                            propelled to great heights of success as AI experts and tech enthusiasts who work daily to
                            maintain the highest standards of quality and ethics at IndiaGPT.</p>
                        <p class="description mt-3">We also make sure to constantly evolve according to the changing trends
                            of the AI world to provide users with the best in class and industry-trending AI solutions to
                            remain at the forefront of the AI revolution. We are also committed to elevating AI standards
                            across the nation while inspiring others around the world.</p>
                    </div>
                    <div class="px-3 flex-grow-1">
                        <h4 class="sub-headline-decor-small">Key Features that sets us apart</h4>
                        <ul>
                            <li class="description"><b>Commitment:</b> As mentioned above, we are committed to delivering
                                reliability, efficiency, creative AI-powered outputs, and the highest quality AI solutions
                                to people across industries. The cherry on top is our user-centric design and the ease of
                                use we offer them while using the platform.</li>
                            <li class="description"><b>Huge data stream:</b> We have trained our AI model on a vast range of
                                data, which helps it engage in meaningful conversations across various topics and
                                industries.</li>
                            <li class="description"><b>Top-notch design:</b> The team has put in rigorous efforts to design
                                it meticulously to align with real-world applications, making it a valuable asset for
                                educators, business owners, other professionals, and everyone seeking to explore the massive
                                power of language. Our user-friendly interface doesn’t confuse; instead, it motivates
                                everyone to have a seamless experience with the platform, catering to all, whether a
                                seasoned AI expert or a beginner.</li>
                            <li class="description"><b>Tailored solutions:</b> At IndiaGPT, we have ensured the platform
                                offers people AI solutions tailored to their demands and prompts. This way, we help empower
                                professionals and businesses with the platform to drive a positive change in their
                                industries eventually.
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

            <div class="row ">

                <div class="col-md-12">
                    <div class="mt-5 py-5">
                        <div class="flex-grow-1">
                            <h3 class="sub-headline-decor-small">IndiaGPT on its Way to Becoming the Future of Communication</h3>
                        </div>
                        <p class="description mt-3">IndiaGPT, as a unique AI-powered platform, stands tall and poised at the forefront of revolutionizing communication. It is well on its way to becoming a trusted and unparalleled AI-powered communication hub. We have ensured the seamless integration of the power and prowess of Open AI’s GPT technology and envisioned creating an inclusive, innovation-centric, and revolutionary platform.</p>
                        <p class="description mt-3">Helping professionals with their queries and questions or helping beginners or students with their issues, we at IndiaGPT create contextually relevant conversations that have now redefined how individuals and businesses connect, converse, and collaborate.</p>
                        <p class="description mt-3">Our commitment to responsible and ethical AI has helped us offer transparent, secure communication aligned with the users’ values. We can confidently say that, gradually, we will become the indispensable medium for effortless and technologically advanced communication.</p>
                        <p class="description mt-3">In essence, IndiaGPT is committed to becoming a catalyst of positive change by harnessing the infinite power of AI to create a future where AI benefits everyone.</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "AboutUs"
}
</script>

<style>

</style>